import firebaseApp from '@/app/database/firebase-app';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const rotateAccessKey = async (): Promise<string> => {
  const auth = getAuth(firebaseApp);

  const token = await auth.currentUser.getIdToken();

  const result = await axios.post(`/api/rotateAccessKey`, null, {
    headers: { token },
    timeout: 0
  });

  return result.data;
};

export default {
  rotateAccessKey
};
