import firebaseApp from '../firebase-app';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { QuestionData } from '@/app/types/database/QuestionData';

const subscribeToQuestions = (
  onChange: (questionsMap: { [id: string]: QuestionData }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(
    doc(db, 'questions', auth.currentUser.uid),
    (doc) => {
      const questions = (doc.data() as { [id: string]: QuestionData }) || {};

      onChange(questions);
    }
  );

  return unsub;
};

export default {
  subscribeToQuestions
};
