import firebaseApp from '@/app/database/firebase-app';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const getStudyGuide = async (deckId: string, language?: string) => {
  const auth = getAuth(firebaseApp);

  try {
    return (
      await axios.get<string>('/api/studyGuide', {
        headers: { token: await auth.currentUser.getIdToken() },
        timeout: 0,
        params: { deckId, language }
      })
    ).data;
  } catch (e) {
    return (
      await axios.get<string>(`/external/api/studyGuide`, {
        headers: { authorization: await auth.currentUser.getIdToken() },
        timeout: 0,
        params: { deckId }
      })
    ).data;
  }
};

export default {
  getStudyGuide
};
