import { CardData } from '@/app/types/database/DeckData';
import { shuffle } from '@/app/utils/arrayUtils';

const parseCardAnswer = (card: CardData, isImageOcclusion: boolean) => {
  if (isImageOcclusion) {
    return JSON.parse(card.answer).text;
  }

  return card.answer;
};

export const buildCardChoices = (
  currentCard: CardData,
  cards: CardData[],
  isImageOcclusion: boolean,
  maxChoices: number
) => {
  const otherCards = cards.filter(
    (t) => !currentCard.answer.includes(t.answer)
  );

  const choices = [parseCardAnswer(currentCard, isImageOcclusion)];

  const shuffledOtherCards = shuffle(otherCards);

  for (const otherCard of shuffledOtherCards) {
    choices.push(parseCardAnswer(otherCard, isImageOcclusion));

    if (choices.length === maxChoices) {
      break;
    }
  }

  const shuffledChoices = shuffle(choices);

  const prefixes = ['', '', '', ''];

  return {
    choices: shuffledChoices.map((choice, index) => prefixes[index] + choice),
    answerIndex: shuffledChoices.indexOf(
      parseCardAnswer(currentCard, isImageOcclusion)
    )
  };
};

export const getImageDisplayDimensions = (image, scale) => {
  var studyContainer = document.getElementById('study-container');

  if (!studyContainer || !image) {
    return { width: 400, height: 400 };
  }

  var modalWidth = studyContainer.clientWidth;
  var modalHeight = studyContainer.clientHeight;

  const availableWidth = modalWidth - 50;
  const availableHeight = 400 * scale;

  let imageHeight = availableHeight;
  let imageWidth =
    ((image.bbox[2] - image.bbox[0]) / (image.bbox[3] - image.bbox[1])) *
    imageHeight;

  if (imageWidth > availableWidth) {
    imageWidth = availableWidth;
    imageHeight =
      ((image.bbox[3] - image.bbox[1]) / (image.bbox[2] - image.bbox[0])) *
      imageWidth;
  }

  return { width: imageWidth, height: imageHeight };
};
