import firebaseApp from '../firebase-app';
import { getFirestore, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getUtcNowTimestamp } from '@/app/utils/timeUtils';
import { Surveys } from '@/app/types/database/Surveys';

const updateSurvey1 = async (feature: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'surveys', auth.currentUser.uid),
    {
      survey1: feature,
      survey1Submitted: getUtcNowTimestamp()
    },
    { merge: true }
  );
};

const subscribeToSurveys = (onChange: (surveys: Surveys) => void) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(doc(db, 'surveys', auth.currentUser.uid), (doc) => {
    const surveys = (doc.data() as Surveys) || ({} as Surveys);

    onChange(surveys);
  });

  return unsub;
};

export default {
  updateSurvey1,
  subscribeToSurveys
};
