import { createContext, useEffect, useState } from 'react';
import CustomersDB from '@/app/database/customers/CustomersDB';
import 'firebase/compat/auth';
import { useAuth } from '../AuthContext/useAuth';
import { StripeSubscription } from '@/app/types/database/StripeSubscription';
import { Subscription } from '@/app/types/database/Subscription';
import LocationAPI from '@/app/api/LocationAPI/LocationAPI';
import { RunData } from '@/app/types/database/RunData';
import RunsDB from '@/app/database/runs/RunsDB';
import {
  CountryInfo,
  supportedCountries
} from '@/app/constants/SupportedCountries';
import useFacebookTracking from '@/app/hooks/useFacebookTracking';
import QuestionsDB from '@/app/database/questions/QuestionsDB';
import { QuestionData } from '@/app/types/database/QuestionData';
import { useFeedback } from '../FeedbackContext/useFeedback';
import { usePracticeTest } from '../PracticeTestContext/usePracticeTest';
import { generateResetDates, getResetDate } from '@/app/utils/dateUtils';
import UsageDB from '@/app/database/usage/UsageDB';
import { UsageData } from '@/app/types/database/UsageData';
import { useAnalytics } from '../AnalyticsContext/useAnalytics';
import StripeAPI from '@/app/api/StripeAPI/StripeAPI';
import { useSettings } from '../SettingsContext/useSettings';
import { SubscriptionItemNames } from '@/app/constants/SubsciptionItemNames';

export const CustomerContext = createContext<{
  isCreatingCheckoutSession: boolean;
  isLoadingCustomerPortal: boolean;
  isProEligible: boolean;
  isPro: boolean;
  hasAnyStripeSubscription: boolean;
  country: CountryInfo;
  subscription: Subscription | null;
  createCheckoutSession: (isMonthly: boolean) => void;
  showCustomerPortal: () => void;
  logCheckoutSession: (sessionId: string, success: boolean) => void;
}>({
  isCreatingCheckoutSession: false,
  isLoadingCustomerPortal: false,
  isProEligible: false,
  isPro: false,
  hasAnyStripeSubscription: false,
  country: supportedCountries['US'],
  subscription: null,
  createCheckoutSession: (isMonthly: boolean) => null,
  showCustomerPortal: () => null,
  logCheckoutSession: (sessionId: string, success: boolean) => null
});

const subscriptionPlacholder: Subscription = {
  isPro: false,
  tag: 'FREE',
  name: 'Free',
  uploadsRemaining: 0,
  uploadsPerMonth: 0,
  practiceTestsRemaining: 0,
  practiceTestsPerMonth: 0,
  pagesOrSlidesPerUpload: 0,
  ankiOcclusionsRemaining: 0,
  ankiOcclusionsPerMonth: 0,
  questionsRemaining: 0,
  questionsPerMonth: 0,
  uploadResetDate: new Date(),
  isCancelled: false,
  cancelDate: new Date(),
  isLoaded: false
};

export function CustomerProvider({ children }: any) {
  const { user } = useAuth();
  const facebookTracking = useFacebookTracking();
  const { submitFeedback } = useFeedback();

  const { setProperties } = useAnalytics();

  const { practiceTests } = usePracticeTest();

  const { googleAnalyticsClientId } = useSettings();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const [nonActiveStripeSubscription, setNonActiveStripeSubscription] =
    useState<StripeSubscription | null | undefined>(undefined);
  const [activeStripeSubscription, setActiveStripeSubscription] = useState<
    StripeSubscription | null | undefined
  >(undefined);

  const [subscription, setSubscription] = useState<Subscription>(
    subscriptionPlacholder
  );

  const [runs, setRuns] = useState<{ [id: string]: RunData }>({});
  const [questions, setQuestions] = useState<{ [id: string]: QuestionData }>(
    {}
  );
  const [usage, setUsage] = useState<{ [id: string]: UsageData }>({});

  const [isProEligible, setIsProEligible] = useState(false);
  const [country, setCountry] = useState<CountryInfo>(supportedCountries['US']);
  const [countryCode, setCountryCode] = useState<string>('');
  const [isCountryLoaded, setIsCountryLoaded] = useState(false);
  const [isSubscriptionLoaded, setIsSubscriptionLoaded] = useState(false);

  const loadCountry = async () => {
    const countryCode = await LocationAPI.getIpCountry();

    setCountryCode(countryCode);

    if (countryCode in supportedCountries) {
      setIsProEligible(true);
      setCountry(supportedCountries[countryCode]);
    } else {
      setIsProEligible(false);
      setCountry(supportedCountries['US']);
    }

    setIsCountryLoaded(true);
  };

  useEffect(() => {
    loadCountry();
  }, []);

  useEffect(() => {
    if (isCountryLoaded && isSubscriptionLoaded) {
      const userProperties = {
        is_pro_eligible: isProEligible ? 1 : 0,
        is_pro: !!activeStripeSubscription ? 1 : 0,
        currency_code: country.currencyCode,
        country_code: countryCode,
        is_authenticated: user && !user.isAnonymous ? 1 : 0
      };

      setProperties(userProperties);
    }
  }, [
    user,
    isCountryLoaded,
    isSubscriptionLoaded,
    country,
    activeStripeSubscription,
    isProEligible,
    countryCode
  ]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = RunsDB.subscribeToRuns((runs) => {
        setRuns(runs);
      });

      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = UsageDB.subscribeToUsage((usage) => {
        setUsage(usage);
      });

      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = QuestionsDB.subscribeToQuestions((questions) => {
        setQuestions(questions);
      });

      return unsubscribe;
    }
  }, [user]);

  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false);

  const [isLoadingCustomerPortal, setIsLoadingCustomerPortal] = useState(false);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = CustomersDB.subscribeToActiveStripeSubscription(
        (stripeSubscription) => {
          setActiveStripeSubscription(stripeSubscription);
          setIsSubscriptionLoaded(true);
        },
        submitFeedback
      );

      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = CustomersDB.subscribeToNonActiveStripeSubscription(
        (stripeSubscription) => {
          setNonActiveStripeSubscription(stripeSubscription);
        }
      );

      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isAnonymous && activeStripeSubscription !== undefined) {
      const currentSubcription: Subscription = {
        isPro: !!activeStripeSubscription,
        tag: getSubscriptionTag(),
        name: getSubscriptionName(),
        uploadsRemaining: getUploadsRemaining(),
        uploadsPerMonth: getUploadsPerMonth(),
        practiceTestsRemaining: getPracticeTestsRemaining(),
        practiceTestsPerMonth: getPracticeTestsPerMonth(),
        pagesOrSlidesPerUpload: getPagesOrSlidesPerUpload(),
        ankiOcclusionsRemaining: getAnkiOcclusionsRemaining(),
        ankiOcclusionsPerMonth: getAnkiOcclusionsPerMonth(),
        questionsRemaining: getQuestionsRemaining(),
        questionsPerMonth: getQuestionsPerMonth(),
        uploadResetDate: getUploadResetDate(),
        isCancelled: !!activeStripeSubscription?.cancel_at_period_end,
        cancelDate: activeStripeSubscription?.cancel_at?.toDate(),
        isLoaded: true
      };

      setSubscription(currentSubcription);

      try {
        if (activeStripeSubscription) {
          const diffInMs =
            new Date().getTime() -
            activeStripeSubscription.created.toDate().getTime();
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

          if (diffInDays < 1) {
            facebookTracking.trackPurchase(
              (activeStripeSubscription as any).items.length > 0 &&
                (activeStripeSubscription as any).items[0]?.price?.id ===
                  country.monthlyPriceId
                ? 5
                : 40
            );
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setSubscription(subscriptionPlacholder);
    }
  }, [user, activeStripeSubscription, runs, questions, usage]);

  const logCheckoutSession = async (sessionId: string, success: boolean) => {
    try {
      const sessionDetails = await StripeAPI.getCheckoutSessionDetails(
        sessionId
      );

      const eventName = success ? 'complete_checkout' : 'cancel_checkout';

      trackEvent(analyticsInstance, eventName as string, {
        currency: sessionDetails.currency,
        value: sessionDetails.total
      });
    } catch (e) {
      trackEvent(analyticsInstance, 'checkout_log_error', {
        error: e.toString()
      });
    }
  };

  const createCheckoutSession = async (isMonthly: boolean) => {
    setIsCreatingCheckoutSession(true);

    const onChange = (url: string, error: string) => {
      if (error) {
        alert(error);
      }

      window.location.assign(url);
    };

    facebookTracking.trackCheckoutSesion(isMonthly ? 5 : 40);

    trackEvent(analyticsInstance, 'begin_checkout', {
      currency: country.currencyCode,
      price_id: isMonthly ? country.monthlyPriceId : country.yearlyPriceId,
      value: isMonthly ? country.monthlyRate : country.yearlyRate,
      items: [
        {
          item_name: SubscriptionItemNames.LimbiksProNewSubscription
        }
      ]
    });

    await CustomersDB.createAndSubscribeToCheckoutSession(
      isMonthly,
      country,
      onChange
    );
  };

  const showCustomerPortal = async () => {
    setIsLoadingCustomerPortal(true);
    const url = await CustomersDB.getCustomerPortalUrl();
    window.location.assign(url);
    setIsLoadingCustomerPortal(false);
  };

  const getSubscriptionTag = () => {
    if (activeStripeSubscription) {
      return 'PRO';
    } else {
      return 'FREE';
    }
  };

  const getSubscriptionName = () => {
    if (activeStripeSubscription) {
      return 'Limbiks Pro';
    } else {
      return 'Free';
    }
  };

  const getSubscriptionStartDate = () => {
    return activeStripeSubscription
      ? activeStripeSubscription.current_period_start.toDate()
      : new Date(user.metadata.creationTime);
  };

  const getUploadResetDate = () => {
    const startDate = getSubscriptionStartDate();
    return getResetDate(startDate);
  };

  const getUploadsRemaining = () => {
    const startDate = getSubscriptionStartDate();
    const resetDates = generateResetDates(startDate);
    const soonestDateIndex = resetDates.findIndex((date) => date > new Date());
    const minDate = resetDates[soonestDateIndex - 1];
    const maxDate = resetDates[soonestDateIndex];

    // TODO: Update verbage throughout code base to be runs

    const uploadsThisMonth = Object.entries(runs).filter(([_key, value]) => {
      return (
        value.cardCount > 0 &&
        value.runDate.toDate() > minDate &&
        value.runDate.toDate() < maxDate
      );
    });

    const uploadsPerMonth = getUploadsPerMonth();

    return Math.max(0, uploadsPerMonth - uploadsThisMonth.length);
  };

  const getPagesOrSlidesPerUpload = () => {
    if (activeStripeSubscription) {
      return 200;
    } else {
      return 5;
    }
  };

  const getUploadsPerMonth = () => {
    if (activeStripeSubscription) {
      return 100;
    } else {
      return 10;
    }
  };

  const getAnkiOcclusionsPerMonth = () => {
    if (activeStripeSubscription) {
      return 500;
    } else {
      return 10;
    }
  };

  const getAnkiOcclusionsRemaining = () => {
    const startDate = getSubscriptionStartDate();
    const resetDates = generateResetDates(startDate);
    const soonestDateIndex = resetDates.findIndex((date) => date > new Date());
    const minDate = resetDates[soonestDateIndex - 1];
    const maxDate = resetDates[soonestDateIndex];

    const ankiOcclusionsThisMonth = Object.entries(usage).filter(
      ([_key, value]) => {
        return (
          value.usageType === 'anki_occlusion' &&
          value.created.toDate() > minDate &&
          value.created.toDate() < maxDate
        );
      }
    );

    const ankiOcclusionsPerMonth = getAnkiOcclusionsPerMonth();

    return Math.max(
      -1,
      ankiOcclusionsPerMonth - ankiOcclusionsThisMonth.length
    );
  };

  const getQuestionsRemaining = () => {
    const startDate = getSubscriptionStartDate();
    const resetDates = generateResetDates(startDate);
    const soonestDateIndex = resetDates.findIndex((date) => date > new Date());
    const minDate = resetDates[soonestDateIndex - 1];
    const maxDate = resetDates[soonestDateIndex];

    // TODO: Update verbage throughout code base to be runs

    const questionsThisMonth = Object.entries(questions).filter(
      ([_key, value]) => {
        return (
          value.questionDate.toDate() > minDate &&
          value.questionDate.toDate() < maxDate
        );
      }
    );

    const questionsPerMonth = getQuestionsPerMonth();

    return Math.max(-1, questionsPerMonth - questionsThisMonth.length);
  };

  const getPracticeTestsRemaining = () => {
    const startDate = getSubscriptionStartDate();
    const resetDates = generateResetDates(startDate);
    const soonestDateIndex = resetDates.findIndex((date) => date > new Date());
    const minDate = resetDates[soonestDateIndex - 1];
    const maxDate = resetDates[soonestDateIndex];

    // TODO: Update verbage throughout code base to be runs

    const practiceTestsThisMonth = Object.entries(practiceTests).filter(
      ([_key, value]) => {
        return (
          value.created.toDate() > minDate &&
          // Only count practice tests after threshold release date
          value.created.toDate() > new Date('2023-11-22T12:00:00Z') &&
          value.created.toDate() < maxDate
        );
      }
    );

    if (activeStripeSubscription) {
      // if active stripe subscription they have unlimited practice tests, so always return 1
      return 1;
    } else {
      const practiceTestsPerMonth = 10;
      return Math.max(
        -1,
        practiceTestsPerMonth - practiceTestsThisMonth.length
      );
    }
  };

  const getQuestionsPerMonth = () => {
    if (activeStripeSubscription) {
      return -1;
    } else {
      return 100;
    }
  };

  const getPracticeTestsPerMonth = () => {
    if (activeStripeSubscription) {
      return -1;
    } else {
      return 10;
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        isCreatingCheckoutSession,
        isProEligible,
        isPro: !!activeStripeSubscription,
        hasAnyStripeSubscription:
          !!activeStripeSubscription || !!nonActiveStripeSubscription,
        country,
        createCheckoutSession,
        showCustomerPortal,
        subscription,
        isLoadingCustomerPortal,
        logCheckoutSession
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}
