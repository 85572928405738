import { getAuth } from 'firebase/auth';
import firebaseApp from '../firebase-app';
import { getFirestore, onSnapshot, doc, Timestamp } from 'firebase/firestore';

const subscribeToAccessKey = (
  onChange: (data: { key: string; created: Timestamp } | undefined) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(
    doc(db, 'userAccessKeys', auth.currentUser.uid),
    (doc) => {
      const data = doc.data();
      onChange((data as { key: string; created: Timestamp }) || undefined);
    }
  );

  return unsub;
};

export default {
  subscribeToAccessKey
};
