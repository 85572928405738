import React, { useState } from 'react';
import GoogleButton from '../common/google-button/GoogleButton';
import SubmitButton from '../common/submit-button/SubmitButton';
import MethodDivider from '../common/method-divider/MethodDivider';
import EmailField from '../common/email-field/EmailField';
import PasswordField from '../common/password-field/PasswordField';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import Image from 'next/image';
import orangeLogo from 'public/orange-logo.png';

interface IProps {
  onSignIn: () => void;
}

export default function CreateAccountForm(props: IProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { signUpEmailAndPassword, redirectUrl } = useAuth();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const onSignUpEmailAndPassword = async () => {
    trackEvent(analyticsInstance, 'auth_click_sign_up_email');
    const success = await signUpEmailAndPassword(email, password);
  };

  let title = 'Sign Up';

  if (redirectUrl?.pathname?.includes('/app/account/key')) {
    title = 'Sign up and get your Limbiks Key';
  } else if (redirectUrl?.pathname?.includes('/app/account')) {
    title = 'Create an account to setup your Limbiks Pro Subscription';
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Image
          className="mx-auto h-10 w-auto"
          src={orangeLogo}
          alt="Limbiks logo"
        />
        <h2 className="mt-6 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {title}
        </h2>

        <p className="mt-2 text-left text-sm text-gray-500">
          Already a member?{' '}
          <a
            href="#"
            onClick={props.onSignIn}
            className="font-semibold leading-6 text-primary hover:text-gray-900"
          >
            Login
          </a>
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSignUpEmailAndPassword();
          }}
        >
          <div className="space-y-2">
            <EmailField
              value={email}
              onChange={(newValue) => setEmail(newValue)}
            />
            <PasswordField
              value={password}
              onChange={(newValue) => setPassword(newValue)}
              label="Password"
            />
            <PasswordField
              value={confirmPassword}
              onChange={(newValue) => setConfirmPassword(newValue)}
              label="Confirm Password"
            />
          </div>
          <SubmitButton
            disabled={!email || !password || password !== confirmPassword}
            text="Create account"
          />
        </form>

        <MethodDivider text="Or sign up with" />
        <div className="flex flex-col gap-3 md:flex-row">
          <GoogleButton />
        </div>
      </div>
    </div>
  );
}
