import { PredefinedPromptKey } from '@/app/constants/PredefinedPrompt';
import firebaseApp from '@/app/database/firebase-app';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const getGenericResponse = async (context: string, input: string) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.get<{ response: string }>(
    process.env.NEXT_PUBLIC_GET_GENERIC_RESPONSE_FUNCTION_URL,
    {
      headers: { authorization: await auth.currentUser.getIdToken() },
      timeout: 0,
      params: {
        context,
        input
      }
    }
  );

  return response.data.response;
};

const getPredefinedResponse = async (
  predefinedPromptKey: PredefinedPromptKey,
  content: string
) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.post<any>(
    '/api/predefinedResponse',
    {
      predefinedPromptKey,
      content
    },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );

  return response.data;
};

export default {
  getGenericResponse,
  getPredefinedResponse
};
