import React, { createContext, memo, useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import DecksDB from '@/app/database/decks/DecksDB';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { DeckStatus } from '@/app/constants/DeckStatus';
import DeckAPI from '@/app/api/DeckAPI/DeckAPI';
import { DeckMetadata } from '@/app/types/database/DeckMetadata';
import { getDeckIdFromRoute } from '@/app/utils/routeUtils';
import { CardData, DeckData } from '@/app/types/database/DeckData';
import { getAuth } from 'firebase/auth';
import firebaseApp from '@/app/database/firebase-app';
import { insertAt, shuffle } from '@/app/utils/arrayUtils';
import { IndexType } from '@/app/constants/IndexType';
import {
  Generator,
  GeneratorOptions
} from '@/app/types/Generator/GeneratorOptions';
import { v4 as uuidv4 } from 'uuid';
import { MultipleChoiceQuestion } from '@/app/types/database/MultipleChoiceQuestion';
import { buildCardChoices } from '@/components/study/study-mode/utils';
import DistractorsAPI from '@/app/api/DistractorsAPI/DistractorsAPI';
import useFacebookTracking from '@/app/hooks/useFacebookTracking';
import StudyGuideAPI from '@/app/api/StudyGuideAPI/StudyGuideAPI';
import { ParsedContent } from '@/app/types/Generator/ParsedContent';
import FeedbackDB from '@/app/database/feedback/FeedbackDB';
import { useFeedback } from '../FeedbackContext/useFeedback';
import ShareQueueDB from '@/app/database/shareQueue/ShareQueueDB';
import { CardSchedule } from '@/app/types/database/CardSchedule';
import * as fsrsJs from 'fsrs.js';
import { getUpdatedCardSchedule } from '@/components/memorize/common/scheduler/Scheduler';
import { DeckMedia } from '@/app/types/Generator/DeckMedia';
import { isSignedUrlExpiring } from '@/app/utils/timeUtils';
import { OcclusionData } from '@/app/types/Generator/OcclusionData';
import { buildAnkiNotes } from '@/components/deck-home/anki-connect-button/utils';
import { useAnalytics } from '../AnalyticsContext/useAnalytics';

interface IDeckContext {
  decks: { [id: string]: DeckMetadata };
  sharedDecks: { [id: string]: DeckMetadata };
  uploadingDeck: string;
  isSavingChange: boolean;
  selectedDeck: DeckMetadata | null;
  selectedDeckIndexType: IndexType | null;
  selectedDeckData: DeckData | null;
  cardSchedules: CardSchedule[];
  cardSchedulesDue: CardSchedule[];
  selectedDeckLoading: boolean;
  selectedDeckLearningObjectives: string;
  selectedDeckCustomInstructions: string;
  decksLoaded: boolean;
  media: DeckMedia[];
  isMediaLoading: boolean;
  occlusions: OcclusionData[];
  isOcclusionsLoading: boolean;
  refresh: () => void;
  downloadAnkiPackage: () => void;
  createDeck: (
    name: string,
    file: any,
    videoId?: string,
    folderId?: string,
    parsedContent?: ParsedContent[],
    customPages?: number[]
  ) => void;
  createCard: (
    deckId: string,
    question: string,
    answer: string,
    sourceIndex?: number,
    indexType?: IndexType,
    afterCardId?: string
  ) => void;
  editDeckName: (deckId: string, name: string) => void;
  generateFlashcards: (
    deckId: string,
    options: GeneratorOptions,
    parsedContent: ParsedContent[] | null
  ) => void;
  deleteDeck: (id: string, skipConfirm?: boolean) => void;
  getStudyGuide: (deckId: string) => string;
  deleteCard: (deckId: string, cardId: string, skipConfirm?: boolean) => void;
  deleteSelectedDeckCards: (deckId: string, cardIds: string[]) => void;
  updateCard: (
    deckId: string,
    cardId: string,
    question: string,
    answer: string
  ) => void;
  updateSelectedDeckData: (updatedData: DeckData) => void;
  mergeDecks: (deckIds: string[]) => void;
  setIsMemorizing: (deckId: string, isMemorizing: boolean) => void;
  getLearningObjectives: (deckId: string) => string;
  getCustomInstructions: (deckId: string) => string;
  cacheLearningObjectives: (deckId: string, content: string) => void;
  cacheCustomInstructions: (deckId: string, content: string) => void;
  changeFolder: (deckId: string, folderId: string | null) => void;
  generateMultipleChoiceQuestion: (
    cardId: string,
    useGpt: boolean
  ) => MultipleChoiceQuestion;
  uploadProgress: number;
  generateMultipleChoiceQuestionFromCardData: (
    card: CardData,
    useGpt: boolean
  ) => MultipleChoiceQuestion;
  getCards: (deckId: string) => CardData[];
  saveReview: (cardSchedule: CardSchedule, rating: fsrsJs.Rating) => null;
  resetCardSchedules: (deckId: string) => null;
  refreshMediaIfExpiring: () => null;
}

export const DeckContext = createContext<IDeckContext>({
  decks: {},
  sharedDecks: {},
  uploadingDeck: null,
  selectedDeck: null,
  isSavingChange: false,
  selectedDeckIndexType: null,
  selectedDeckData: null,
  decksLoaded: false,
  cardSchedules: [],
  cardSchedulesDue: [],
  selectedDeckLearningObjectives: null,
  selectedDeckCustomInstructions: null,
  selectedDeckLoading: false,
  media: [],
  isMediaLoading: true,
  occlusions: [],
  isOcclusionsLoading: true,
  refresh: () => null,
  downloadAnkiPackage: () => null,
  createDeck: (
    name: string,
    file: any,
    videoId?: string,
    folderId?: string,
    parsedContent?: ParsedContent[],
    customPages?: number[]
  ) => null,
  editDeckName: (deckId: string, name: string) => null,
  generateFlashcards: (
    deckId: string,
    options: GeneratorOptions,
    parsedContent: ParsedContent[] | null
  ) => null,
  createCard: (
    deckId: string,
    question: string,
    answer: string,
    sourceIndex?: number,
    indexType?: IndexType,
    afterCardId?: string
  ) => null,
  deleteDeck: (id: string, skipConfirm = false) => null,
  mergeDecks: (deckIds: string[]) => null,
  setIsMemorizing: (deckId: string, isMemorizing: boolean) => null,
  getStudyGuide: (deckId: string) => '',
  updateSelectedDeckData: (updatedData: DeckData) => null,
  deleteCard: (deckId: string, cardId: string, skipConfirm?: boolean) => null,
  deleteSelectedDeckCards: (deckId: string, cardIds: string[]) => null,
  updateCard: (
    deckId: string,
    cardId: string,
    question: string,
    answer: string
  ) => null,
  getLearningObjectives: (deckId: string) => null,
  getCustomInstructions: (deckId: string) => null,
  cacheLearningObjectives: (deckId: string) => null,
  cacheCustomInstructions: (deckId: string) => null,
  changeFolder: (deckId: string, folderId: string | null) => null,
  generateMultipleChoiceQuestion: (cardId: string, useGpt: boolean) => null,
  generateMultipleChoiceQuestionFromCardData: (
    card: CardData,
    useGpt: boolean
  ) => null,
  uploadProgress: 0,
  getCards: (deckId: string) => [],
  saveReview: (cardSchedule: CardSchedule, rating: fsrsJs.Rating) => null,
  resetCardSchedules: (deckId: string) => null,
  refreshMediaIfExpiring: () => null
});

export const DeckProvider = (props) => {
  const [decks, setDecks] = useState<{ [id: string]: DeckMetadata }>({});
  const [sharedDecks, setSharedDecks] = useState<{
    [id: string]: DeckMetadata;
  }>({});

  const [uploadingDeck, setUploadingDeck] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [decksLoaded, setDecksLoaded] = useState<boolean>(false);
  const [cardSchedulesLoaded, setCardSchedulesLoaded] =
    useState<boolean>(false);
  const [cardSchedules, setCardSchedules] = useState<CardSchedule[]>([]);
  const { user, token } = useAuth();
  const { submitFeedback } = useFeedback();
  const router = useRouter();
  const { trackEvent, analyticsInstance } = useAnalytics();

  const [selectedDeckId, setSelectedDeckId] = useState<string | null>(null);
  const [selectedDeckLoading, setSelectedDeckLoading] = useState<boolean>(true);
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [isOcclusionsLoading, setIsOcclusionsLoading] = useState(true);
  const [media, setMedia] = useState<DeckMedia[]>([]);
  const [occlusions, setOcclusions] = useState<OcclusionData[]>([]);

  const [selectedDeckData, setSelectedDeckData] = useState<DeckData | null>(
    null
  );

  const [cachedDeckData, setCachedDeckData] = useState<{
    [id: string]: DeckData;
  }>({});

  const [isSavingChange, setIsSavingChange] = useState(false);
  const [selectedDeckLearningObjectives, setSelectedDeckLearningObjectives] =
    useState(null);
  const [selectedDeckCustomInstructions, setSelectedDeckCustomInstructions] =
    useState(null);

  const selectedDeck = { id: selectedDeckId, ...decks[selectedDeckId] };

  const auth = getAuth(firebaseApp);
  const facebookTracking = useFacebookTracking();

  // On Metadata Loaded - Check for deck in route and prepare page as needed
  useEffect(() => {
    if (decksLoaded) {
      const deckId = getDeckIdFromRoute();

      if (deckId && !decks[deckId]) {
        // send the user back home if we don't have this deck in metadata anymore
        router.replace('/app');
      } else if (deckId) {
        setSelectedDeckId(deckId);
      } else {
        setSelectedDeckLoading(false);
      }
    }
  }, [decksLoaded]);

  const saveReview = async (
    cardSchedule: CardSchedule,
    rating: fsrsJs.Rating
  ) => {
    const newCardSchedules = [
      ...cardSchedules.filter((cs) => cs.deckId === cardSchedule.deckId)
    ];

    const updatedCardSchedule = getUpdatedCardSchedule(cardSchedule, rating);

    const index = newCardSchedules.findIndex(
      (cs) => cs.cardId === cardSchedule.cardId
    );

    newCardSchedules[index] = updatedCardSchedule;

    setCardSchedules(newCardSchedules);

    setIsSavingChange(true);

    await DecksDB.updateCardSchedules(cardSchedule.deckId, newCardSchedules);

    setIsSavingChange(false);
  };

  useEffect(() => {
    if (token) {
      const unsubscribe = DecksDB.subscribeToDecks((currentDecks) => {
        setDecks(currentDecks);
        setDecksLoaded(true);
      });

      return () => unsubscribe();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const unsubscribe = DecksDB.subscribeToCardSchedules((cardSchedules) => {
        setCardSchedules(cardSchedules);
        setCardSchedulesLoaded(true);
      });

      return () => unsubscribe();
    }
  }, [token]);

  const ensureCardSchedules = async (
    deckId: string,
    cardSchedules: CardSchedule[],
    selectedDeckData: DeckData
  ) => {
    const deckCardSchedules = cardSchedules.filter(
      (cs) => cs.deckId === deckId
    );

    const missingCardSchedules = selectedDeckData.cards.filter(
      (c) => !deckCardSchedules.some((cs) => cs.cardId === c.id)
    );
    const orphanedCardSchedules = deckCardSchedules.filter(
      (cs) => !selectedDeckData.cards.some((c) => c.id === cs.cardId)
    );

    // If there are any cards that have been added or deleted we should update the card schedules
    if (missingCardSchedules.length > 0 || orphanedCardSchedules.length > 0) {
      const newDeckCardSchedules: CardSchedule[] = deckCardSchedules.filter(
        (cs) => !orphanedCardSchedules.some((ocs) => ocs.cardId === cs.cardId)
      );

      missingCardSchedules.forEach((mcs) => {
        const newCardSchedule = new fsrsJs.Card();

        newDeckCardSchedules.push({
          deckId: deckId,
          cardId: mcs.id,
          ...newCardSchedule
        } as CardSchedule);
      });

      await DecksDB.updateCardSchedules(deckId, newDeckCardSchedules);
    }
  };

  useEffect(() => {
    if (cardSchedules && cardSchedulesLoaded && selectedDeckData) {
      ensureCardSchedules(selectedDeckId, cardSchedules, selectedDeckData);
    }
  }, [cardSchedulesLoaded, selectedDeckData]);

  useEffect(() => {
    if (token) {
      try {
        const unsubscribe = ShareQueueDB.subscribeToQueue((decks) => {
          setSharedDecks(decks);
        });

        return () => unsubscribe();
      } catch (e) {}
    }
  }, [token]);

  useEffect(() => {
    if (selectedDeckId) {
      setSelectedDeckLoading(true);
      const unsubscribe = DecksDB.subscribeToData(selectedDeckId, (data) => {
        setSelectedDeckData(data);
        setSelectedDeckLoading(false);
        setCachedDeckData({ ...cachedDeckData, [selectedDeckId]: data });
      });

      return () => unsubscribe();
    }
  }, [selectedDeckId]);

  useEffect(() => {
    if (selectedDeckId) {
      setSelectedDeckLearningObjectives(getLearningObjectives(selectedDeckId));
      setSelectedDeckCustomInstructions(getCustomInstructions(selectedDeckId));
    }
  }, [selectedDeckId]);

  const loadMedia = async (deckId: string) => {
    try {
      setIsMediaLoading(true);
      const media = await DeckAPI.getMedia(deckId);
      setMedia(media);
    } catch {
      setMedia([]);
    }

    setIsMediaLoading(false);
  };

  const loadOcclusions = async (deckId: string) => {
    try {
      setIsOcclusionsLoading(true);
      const occlusions = await DeckAPI.getAvailableOcclusions(deckId);
      setOcclusions(occlusions);
    } catch {
      setOcclusions([]);
    }

    setIsOcclusionsLoading(false);
  };

  const refreshMediaIfExpiring = async () => {
    if (
      !isMediaLoading &&
      media.length > 0 &&
      selectedDeckId &&
      selectedDeck &&
      selectedDeck.status === DeckStatus.Complete
    ) {
      const signedUrlImage = media.find((t) => t.signedUrl);

      if (isSignedUrlExpiring(signedUrlImage.signedUrl, 60)) {
        await loadMedia(selectedDeckId);
      }
    }
  };

  useEffect(() => {
    if (
      selectedDeckId &&
      selectedDeck &&
      selectedDeck.status === DeckStatus.Complete &&
      selectedDeck.isImageOcclusion
    ) {
      loadMedia(selectedDeckId);
      loadOcclusions(selectedDeckId);

      // since media has signed urls that expire, refresh media every hour if the user does not leave the page
      const handle = setInterval(async () => {
        loadMedia(selectedDeckId);
      }, 60 * 60 * 1000);

      // clean up setInterval
      return () => clearInterval(handle);
    }
  }, [selectedDeckId, selectedDeck?.status]);

  useEffect(() => {
    setSelectedDeckId(null);
    const deckId = getDeckIdFromRoute();
    if (decksLoaded && decks[deckId]) {
      setSelectedDeckId(deckId);
    }
  }, [router.query]);

  const createDeck = async (
    name: string,
    file: any,
    videoId?: string,
    folderId?: string,
    parsedContent?: ParsedContent[],
    customPages?: number[]
  ) => {
    setSelectedDeckId(null);

    const fileType = file.name.split('.').slice(-1)[0].toLowerCase();

    const newDeckId = await DecksDB.create(name, fileType, videoId, folderId);

    const auth = getAuth(firebaseApp);

    setUploadingDeck(newDeckId);

    if (auth.currentUser.isAnonymous) {
      // If anonymous, we are in preview mode and want to subsribe to the card updates
      setSelectedDeckId(newDeckId);
    }

    const uploadUrl = await DeckAPI.getUploadUrl(newDeckId);

    setUploadProgress(0);

    try {
      await DeckAPI.upload(uploadUrl, file, (percent) => {
        setUploadProgress(percent);
      });
    } catch {
      setUploadingDeck(null);
      setUploadProgress(0);
      return;
    }

    if (auth.currentUser.isAnonymous) {
      trackEvent(analyticsInstance, 'preview_upload_complete', {
        file_type: fileType
      });
    } else {
      trackEvent(analyticsInstance, 'generate_upload_complete', {
        file_type: fileType
      });
    }

    setUploadingDeck(null);
    setUploadProgress(0);

    // If anonymous, skip right to preview
    if (auth.currentUser.isAnonymous) {
      const options: GeneratorOptions = {
        generator: 'Question',
        customPages: customPages ?? [],
        // TODO: If pdf.js works well we may want to look at skipping parsing for anonymous uploads
        skipParsing: false,
        maxFlashcards: -1,
        generatorBehavior: 'Default'
      };

      if (fileType === 'pptx') {
        options.includeSlideContent = true;
        options.includeNotesSection = true;
      }

      generateFlashcards(
        newDeckId,
        options,
        parsedContent ? parsedContent : null
      );

      facebookTracking.trackPreview();
    } else {
      if (fileType === 'png' || fileType === 'jpg') {
        // jpg and png can skip preprocessing
        // previewImages endpoint will return the uploaded image directly to the front end

        await DecksDB.updateMetadata(newDeckId, {
          status: DeckStatus.Ready,
          pageCount: 1
        });
      } else {
        await DecksDB.updateMetadata(newDeckId, {
          status: DeckStatus.Preprocessing
        });

        DeckAPI.preprocess(newDeckId);
      }

      router.push('/app/' + newDeckId);
    }
  };

  const editDeckName = async (deckId: string, name: string) => {
    await DecksDB.updateMetadata(deckId, {
      name
    });
  };

  const resetCardSchedules = async (deckId: string) => {
    const newCardSchedule = new fsrsJs.Card();
    const data = await DecksDB.getData(deckId);

    const newCardSchedules: CardSchedule[] = data.cards.map(
      (card) =>
        ({
          deckId: deckId,
          cardId: card.id,
          ...newCardSchedule
        } as CardSchedule)
    );

    await DecksDB.updateCardSchedules(deckId, newCardSchedules);

    submitFeedback('Reset Card Schedules - ' + deckId + ' - ' + user.email);
  };

  const setIsMemorizing = async (deckId: string, isMemorizing: boolean) => {
    await DecksDB.updateMetadata(deckId, {
      isMemorizing
    });

    if (isMemorizing && !cardSchedules.some((cs) => cs.deckId === deckId)) {
      await resetCardSchedules(deckId);
    }

    submitFeedback(
      'Set Is Memorizing - ' +
        deckId +
        ' - ' +
        isMemorizing +
        ' - ' +
        user.email
    );
  };

  const changeFolder = async (deckId: string, folderId: string | null) => {
    await DecksDB.updateMetadata(deckId, {
      folderId
    });
  };

  const getStudyGuide = async (deckId: string) => {
    const language =
      selectedDeck &&
      selectedDeck.language?.name &&
      selectedDeck.language?.name.toLowerCase() !== 'english'
        ? selectedDeck.language?.name
        : undefined;

    const studyGuideMarkdown = await StudyGuideAPI.getStudyGuide(
      deckId,
      language
    );
    return studyGuideMarkdown;
  };

  const generateFlashcards = async (
    deckId: string,
    options: GeneratorOptions,
    parsedContent: ParsedContent[] | null
  ) => {
    await DecksDB.updateMetadata(deckId, {
      status: DeckStatus.Generating,
      isImageOcclusion: options.generator === Generator.ImageOcclusion
    });

    try {
      // Try saving learning objectives if they exist
      if (selectedDeckLearningObjectives) {
        trackEvent(analyticsInstance, 'generate_add_learning_objectives');

        await DeckAPI.saveLearningObjectives(
          deckId,
          selectedDeckLearningObjectives
        );
        options.hasLearningObjectives = true;
        deleteLearningObjectives(deckId);
      }
    } catch (e) {
      trackEvent(analyticsInstance, 'generate_add_learning_objectives_failed', {
        error: e.toString()
      });

      FeedbackDB.create(
        `Failed to save learning objectives: ${user.uid}_${deckId}`
      );
    }

    try {
      // Try saving custom instructions if they exist
      if (selectedDeckCustomInstructions) {
        trackEvent(analyticsInstance, 'generate_add_custom_instructions');

        await DeckAPI.saveCustomInstructions(
          deckId,
          selectedDeckCustomInstructions
        );
        options.hasCustomInstructions = true;
        deleteCustomInstructions(deckId);
      }
    } catch (e) {
      trackEvent(analyticsInstance, 'generate_add_custom_instructions_failed', {
        error: editDeckName.toString()
      });

      FeedbackDB.create(
        `Failed to save custom instructions: ${user.uid}_${deckId}`
      );
    }

    try {
      // Try saving the parsed json first.. if it succeed change then set skipParsing = true
      if (parsedContent) {
        try {
          await DeckAPI.saveContent(deckId, parsedContent);
          options.skipParsing = true;
        } catch {
          try {
            FeedbackDB.create(`Failed to save content: ${user.uid}_${deckId}`);
          } catch {}
        }
      }

      await DeckAPI.generate(deckId, options);

      trackEvent(analyticsInstance, 'generate', {
        deck_id: deckId,
        ...options
      });

      await DecksDB.updateMetadata(deckId, {
        generatorRuns: selectedDeck.generatorRuns + 1
      });
    } catch {
      await DecksDB.updateMetadata(deckId, {
        status: DeckStatus.Failed
      });
    }
  };

  const createCard = async (
    deckId: string,
    question: string,
    answer: string,
    sourceIndex?: number,
    indexType?: IndexType,
    afterCardId?: string
  ) => {
    const newCard = {
      id: uuidv4(),
      question,
      answer,
      text: '',
      index: sourceIndex > 0 ? sourceIndex : 0,
      name: sourceIndex > 0 ? `${indexType} ${sourceIndex}` : ''
    } as CardData;

    let closestParentIndex = 10000000000;
    let closestParentLocation = 0;

    if (afterCardId) {
      closestParentLocation =
        (selectedDeckData?.cards.findIndex((t) => t.id === afterCardId) ?? 0) +
        1;
    } else {
      if (newCard.index >= 0) {
        selectedDeckData?.cards.forEach((card, index) => {
          if (
            card.index >= sourceIndex &&
            card.index - sourceIndex < closestParentIndex - sourceIndex
          ) {
            closestParentIndex = card.index;
            closestParentLocation = index;
          }
        });
      }
    }

    const cardsCopy = [...selectedDeckData?.cards];

    let parentCard = cardsCopy[Math.max(closestParentLocation - 1, 0)];

    newCard.dataId = parentCard.dataId;

    insertAt(cardsCopy, closestParentLocation, newCard);

    const updatedData = {
      ...selectedDeckData,
      cards: cardsCopy
    };

    setSelectedDeckData(updatedData);

    setIsSavingChange(true);

    await DecksDB.updateData(deckId, updatedData);

    setIsSavingChange(false);
  };

  const generateMultipleChoiceQuestion = async (
    cardId: string,
    useGpt4: boolean
  ) => {
    const card = selectedDeckData?.cards.find((t) => t.id === cardId);

    if (card?.multipleChoiceQuestion) {
      return card.multipleChoiceQuestion;
    }

    const { choices, answerIndex } = buildCardChoices(
      card,
      selectedDeckData?.cards,
      selectedDeck.isImageOcclusion,
      4
    );

    let generatedChoiceCount = 0;

    let generatedChoices = [];

    try {
      const promptLanguage =
        selectedDeck.language?.name &&
        selectedDeck.language?.name.toLowerCase() !== 'english'
          ? selectedDeck.language?.name
          : undefined;

      generatedChoices = await DistractorsAPI.getEnhancedDistractors(
        card.question,
        selectedDeck.isImageOcclusion
          ? JSON.parse(card.answer).text
          : card.answer,
        useGpt4,
        promptLanguage
      );
    } catch (e) {
      if (user?.uid) {
        submitFeedback('Enhanced Distractors Failed: ' + JSON.stringify(e));
      }

      try {
        generatedChoices = await DistractorsAPI.getSimpleDistractors(
          card.answer
        );
      } catch (e) {
        if (user?.uid) {
          submitFeedback('Simple Distractors Failed: ' + e.toString());
        }
      }
    }

    let currentGeneratedChoice = 0;
    for (var i = 0; i < choices.length; i++) {
      if (
        i !== answerIndex &&
        generatedChoices.length >= currentGeneratedChoice + 1
      ) {
        // avoid duplicates
        if (
          !choices.some(
            (choice) => choice === generatedChoices[currentGeneratedChoice]
          )
        ) {
          choices[i] = generatedChoices[currentGeneratedChoice];
          generatedChoiceCount++;
        }

        currentGeneratedChoice += 1;
      }
    }

    return {
      question: card.question,
      cardId: card.id,
      choices,
      answerIndex,
      isGeneratedOnly: generatedChoiceCount === choices.length - 1
    } as MultipleChoiceQuestion;
  };

  const generateMultipleChoiceQuestionFromCardData = async (
    card: CardData,
    useGpt4: boolean
  ) => {
    if (card?.multipleChoiceQuestion) {
      return card.multipleChoiceQuestion;
    }

    let generatedChoices = [];

    try {
      const promptLanguage =
        selectedDeck.language?.name &&
        selectedDeck.language?.name.toLowerCase() !== 'english'
          ? selectedDeck.language?.name
          : undefined;

      generatedChoices = await DistractorsAPI.getEnhancedDistractors(
        card.question,
        card.answer,
        useGpt4,
        promptLanguage
      );
    } catch (e) {
      if (user?.uid) {
        submitFeedback('Preview Distractors Failed: ' + JSON.stringify(e));
      }
    }

    const fullArray = generatedChoices.slice(0, 3);

    fullArray.push(card.answer);

    const shuffledChoices = shuffle(fullArray);

    return {
      question: card.question,
      choices: shuffledChoices,
      answerIndex: shuffledChoices.indexOf(card.answer),
      isGeneratedOnly: true
    } as MultipleChoiceQuestion;
  };

  const updateSelectedDeckData = async (updatedData: DeckData) => {
    setSelectedDeckData(updatedData);

    setIsSavingChange(true);

    await DecksDB.updateData(selectedDeckId, updatedData);

    setIsSavingChange(false);
  };

  const updateCard = async (
    deckId: string,
    cardId: string,
    question: string,
    answer: string
  ) => {
    const card = selectedDeckData?.cards.find((t) => t.id === cardId);

    const updatedCard = {
      ...card,
      question,
      answer
    } as CardData;

    const updatedData = {
      ...selectedDeckData,
      cards: selectedDeckData.cards.map((card) => {
        if (card.id === updatedCard.id) {
          return updatedCard;
        }

        return card;
      })
    };

    setSelectedDeckData(updatedData);

    setIsSavingChange(true);

    await DecksDB.updateData(deckId, updatedData);

    setIsSavingChange(false);
  };

  const downloadAnkiPackage = async () => {
    const deckName = 'Limbiks::' + selectedDeck.name;

    await refreshMediaIfExpiring();

    const notes = await buildAnkiNotes(
      deckName,
      selectedDeckIndexType,
      !!selectedDeck.isImageOcclusion,
      selectedDeckData,
      media
    );

    const result = await DeckAPI.ankiDownload(selectedDeck.id, deckName, notes);

    if (result.data && result.data.success && result.data.signedUrl) {
      (document.getElementById('download_frame') as any).src =
        result.data.signedUrl;
    } else {
      window.alert('Failed to build Anki Package');
    }
  };

  const deleteDeck = async (id, skipConfirm = false) => {
    let result = true;
    const selectedDeck = decks[id];

    if (!skipConfirm) {
      result = window.confirm(
        `Are you sure you want to delete ${selectedDeck?.name} and all ${selectedDeck?.cardCount} flashcards?`
      );
    }

    if (result) {
      await DecksDB.remove(id);
      router.replace('/app');
    }
  };

  const deleteCard = async (deckId, cardId, skipConfirm = false) => {
    let result = true;

    const deck = decks[deckId];
    const deckData = cachedDeckData[deckId];
    const card = deckData?.cards.find((t) => t.id === cardId);

    if (!card) {
      return;
    }

    if (!skipConfirm) {
      result = window.confirm(
        `Are you sure you want to delete the card "${card.question}" from ${deck?.name}?`
      );
    }

    if (result && deckData) {
      const updatedData = {
        ...deckData,
        cards: deckData.cards.filter((c) => c.id !== cardId)
      };

      if (selectedDeckId === deckId) {
        setSelectedDeckData(updatedData);
      }

      setCachedDeckData({ ...cachedDeckData, [deckId]: deckData });

      await DecksDB.updateData(deckId, updatedData);
    }
  };

  const deleteSelectedDeckCards = async (deckId, cardIds) => {
    let result = true;
    const selectedDeck = decks[deckId];

    if (result && selectedDeck && selectedDeckData) {
      const updatedData = {
        ...selectedDeckData,
        cards: selectedDeckData.cards.filter((c) => !cardIds.includes(c.id))
      };

      setSelectedDeckData(updatedData);

      await DecksDB.updateData(deckId, updatedData);
    }
  };

  const getCards = async (deckId: string) => {
    if (deckId in cachedDeckData) {
      return cachedDeckData[deckId].cards;
    }

    const deckData = await DecksDB.getData(deckId);

    setCachedDeckData({ ...cachedDeckData, [deckId]: deckData });

    return deckData.cards;
  };

  const mergeDecks = async (deckIds: string[]) => {
    if (deckIds.length < 2) {
      window.alert('Please select at least 2 decks to combine.');
      return;
    }

    const firstDeckId = deckIds[0];
    const firstDeck = decks[firstDeckId];

    const result = window.confirm(
      `Are you sure you want to combine the ${deckIds.length} selected Decks?\n\nCombined Deck Name: "${firstDeck.name}".\n\nThe deck name can be changed after merging is complete.`
    );

    if (result) {
      // get the first deck selected that we will add all the cards to
      const firstDeckData = await DecksDB.getData(firstDeckId);

      for (let i = 1; i < deckIds.length; i++) {
        const deckData = await DecksDB.getData(deckIds[i]);

        firstDeckData.cards.push(...deckData.cards);
      }

      await DecksDB.updateData(firstDeckId, firstDeckData);

      // For now don't delete decks when merging based on user feedback
      // for (let i = 1; i < deckIds.length; i++) {
      //   await DecksDB.remove(deckIds[i]);
      // }
    }
  };

  const getLearningObjectives = (deckId: string) => {
    return localStorage.getItem('objectives-' + deckId);
  };

  const cacheLearningObjectives = (deckId: string, content: string) => {
    if (deckId === selectedDeckId) {
      setSelectedDeckLearningObjectives(content);
    }

    return localStorage.setItem('objectives-' + deckId, content);
  };

  const deleteLearningObjectives = (deckId: string) => {
    return localStorage.removeItem('objectives-' + deckId);
  };

  const getCustomInstructions = (deckId: string) => {
    return localStorage.getItem('instructions-' + deckId);
  };

  const cacheCustomInstructions = (deckId: string, content: string) => {
    if (deckId === selectedDeckId) {
      setSelectedDeckCustomInstructions(content);
    }
    return localStorage.setItem('instructions-' + deckId, content);
  };

  const deleteCustomInstructions = (deckId: string) => {
    return localStorage.removeItem('instructions-' + deckId);
  };

  const selectedDeckIndexType = decks[selectedDeckId]
    ? decks[selectedDeckId].name.includes('pptx')
      ? IndexType.Slide
      : IndexType.Page
    : null;

  let lookaheadDate = new Date();
  lookaheadDate.setMinutes(lookaheadDate.getMinutes() + 20);

  const activeCardSchedules = [...cardSchedules]
    .sort((a, b) => a.due.getTime() - b.due.getTime())
    .filter((c) => c.deckId in decks && decks[c.deckId].isMemorizing);

  const cardSchedulesDue = [...cardSchedules]
    .sort((a, b) => a.due.getTime() - b.due.getTime())
    .filter((c) => c.deckId in decks && decks[c.deckId].isMemorizing)
    .filter((c) => c.due < lookaheadDate);

  const value = {
    decks,
    isSavingChange,
    sharedDecks,
    selectedDeck,
    selectedDeckIndexType,
    selectedDeckData,
    selectedDeckLoading,
    cardSchedules: activeCardSchedules,
    cardSchedulesDue,
    selectedDeckLearningObjectives,
    selectedDeckCustomInstructions,
    decksLoaded,
    saveReview,
    getStudyGuide,
    createDeck,
    generateFlashcards,
    createCard,
    media,
    isMediaLoading,
    occlusions,
    isOcclusionsLoading,
    deleteDeck,
    deleteCard,
    deleteSelectedDeckCards,
    updateCard,
    changeFolder,
    uploadProgress,
    uploadingDeck,
    editDeckName,
    setIsMemorizing,
    mergeDecks,
    getCards,
    resetCardSchedules,
    getLearningObjectives,
    getCustomInstructions,
    cacheLearningObjectives,
    cacheCustomInstructions,
    generateMultipleChoiceQuestion,
    refreshMediaIfExpiring,
    updateSelectedDeckData,
    downloadAnkiPackage,
    generateMultipleChoiceQuestionFromCardData
  };

  return <DeckContext.Provider value={value} {...props} />;
};
