import * as fsrsJs from 'fsrs.js';

export interface IProps {
  new: number | string;
  learn: number | string;
  review: number | string;
  highlightState?: fsrsJs.State;
}

export const Stepper = (props: IProps) => {
  const newElement =
    typeof props.new === 'string' || props.new > 0 ? (
      <div
        className={`stage stage-1  ${
          props.highlightState == fsrsJs.State.New ? 'bg-primary' : 'bg-neutral'
        }`}
      >
        <span className="ml-3 text-neutral-content">{props.new}</span>
      </div>
    ) : (
      <div
        data-tip="Nice work! There aren't any flashcards in the new state."
        className="stage stage-1 tooltip flex items-center justify-center bg-green"
      >
        <svg
          width={22}
          height={22}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="ml-4 fill-white"
          fill="currentColor"
        >
          <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
        </svg>
      </div>
    );

  const learnElement =
    typeof props.learn === 'string' || props.learn > 0 ? (
      <div
        className={`stage stage-2  ${
          props.highlightState === fsrsJs.State.Learning ||
          props.highlightState === fsrsJs.State.Relearning
            ? 'bg-primary'
            : 'bg-neutral'
        }`}
      >
        <span className="text-neutral-content">{props.learn}</span>
      </div>
    ) : typeof props.new === 'string' || props.new > 0 ? (
      <div className="stage stage-2 bg-neutral">
        <span className="text-neutral-content">-</span>
      </div>
    ) : (
      <div className="stage stage-2 flex items-center justify-center bg-green">
        <svg
          width={22}
          height={22}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="fill-white"
          fill="currentColor"
        >
          <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
        </svg>
      </div>
    );

  const reviewElement =
    typeof props.review === 'string' || props.review > 0 ? (
      <div
        className={`stage stage-3  ${
          props.highlightState === fsrsJs.State.Review
            ? 'bg-primary'
            : 'bg-neutral'
        }`}
      >
        <span className="text-neutral-content">{props.review}</span>
      </div>
    ) : typeof props.new === 'string' ||
      props.new > 0 ||
      typeof props.learn === 'string' ||
      props.learn > 0 ? (
      <div className="stage stage-3 bg-neutral">
        <span className="text-neutral-content">-</span>
      </div>
    ) : (
      <div className="stage stage-3 flex items-center justify-center bg-green">
        <svg
          width={22}
          height={22}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="fill-white"
          fill="currentColor"
        >
          <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
        </svg>
      </div>
    );

  return (
    <div className="relative h-full w-[13rem]">
      <div className="tracker-bar text-center">
        <div className="tracker-base bg-neutral opacity-50"></div>
        <div className="stages">
          {newElement}
          {/* <div className="stage stage-1 flex items-center justify-center bg-neutral">
            <svg
              width={22}
              height={22}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="fill-neutral-content"
              fill="currentColor"
            >
              <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
            </svg>
          </div> */}
          {learnElement}
          {reviewElement}
          {/* <div className="stage stage-2 bg-neutral">
            <span className="ml-3 text-neutral-content">1000</span>
          </div> */}
          {/* <div className="stage stage-3 flex items-center justify-center bg-neutral">
        
            -
          </div> */}
        </div>
      </div>
    </div>
  );
};
