import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

export default function Help({ onClose }) {
  const router = useRouter();

  const onExamplesClick = () => {
    router.push('/examples');
    onClose();
  };

  return (
    <>
      <div className="w-full text-center text-lg font-bold">
        Studying with Limbiks
      </div>
      <div className="mt-2 w-full text-center">
        Click the big orange button and select a file! After generating your
        flashcards, Limbiks provides a built in flashcard and quiz tool so you
        can quickly start studying. If you already have a flashcard tool you
        prefer, you can download all of your flashcards at any time and import
        them into your prefered flashcard tool!
      </div>
      <div className="mt-2 w-full text-center">
        Please keep in mind that Limbiks may occasionally fail to generate
        flashcards. We are constantly working to improve the platform!
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        Downloading / Importing Flashcards
      </div>
      <div className="mt-2 w-full text-center">
        Limbiks lets you download any of your flashcard decks so you can use
        them with your favorite tool! When downloading flashcards with Limbiks,
        you will get a CSV File containing the front and back of your
        flashcards. This type of file can be imported into most flashcard tools.
        Click on any of these links for import instructions to{' '}
        <span>
          <a
            target="_blank"
            href="https://help.quizlet.com/hc/en-us/articles/360029977151-Creating-sets-by-importing-content"
            className="link place-items-center text-center font-bold text-secondary"
          >
            Quizlet
          </a>
        </span>
        ,{' '}
        <span>
          <a
            target="_blank"
            href="https://docs.ankiweb.net/importing.html"
            className="link place-items-center text-center font-bold text-secondary"
          >
            Anki
          </a>
        </span>
        ,{' '}
        <span>
          <a
            target="_blank"
            href="https://www.cram.com/docs/help#createFlashcards"
            className="link place-items-center text-center font-bold text-secondary"
          >
            Cram
          </a>
        </span>
        , or{' '}
        <span>
          <a
            target="_blank"
            href="https://forum.duolingo.com/comment/28263786/Pro-Tip-Make-Tinycards-decks-by-pasting-CSV-data-in-Spreadsheet-Mode"
            className="link place-items-center text-center font-bold text-secondary"
          >
            Tinycards
          </a>
        </span>
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        Subscriptions
      </div>
      <div className="mt-2 w-full text-center">
        Your Limbiks subscription has a limited number of uploads allowed each
        month. This number will reset monthly relative to your subscription
        start date. Only uploads that successfully generated flashcards will be
        counted towards your monthly limit. Your Limbiks subscription also comes
        with a preset number of pages and slides you can select when generating
        flashcards. For any further questions regarding your subscription,
        please contact{' '}
        <a
          target="_blank"
          href="mailto:support@limbiks.com"
          className="link place-items-center text-center font-bold text-secondary"
        >
          support@limbiks.com
        </a>
        .
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        Not sure where to start?
      </div>
      <div className="mt-2 w-full text-center">
        <span className="cursor-pointer text-blue" onClick={onExamplesClick}>
          Click here
        </span>{' '}
        to see our page of examples. Original files are provided so you can try
        them out yourself!
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
      </div>
    </>
  );
}
