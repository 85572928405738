import firebaseApp from '../firebase-app';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { UsageData } from '@/app/types/database/UsageData';

const subscribeToUsage = (
  onChange: (runsMap: { [id: string]: UsageData }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(doc(db, 'usage', auth.currentUser.uid), (doc) => {
    const usages = (doc.data() as { [id: string]: UsageData }) || {};
    console.log(usages);
    onChange(usages);
  });

  return unsub;
};

export default {
  subscribeToUsage
};
