import React from 'react';
import ForgotPassword from './forgot-password/ForgotPassword';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  onForgotPassword?: () => void;
}

export default function PasswordField(props: IProps) {
  return (
    <div>
      <div className="flex items-center justify-between">
        <label
          htmlFor="password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
        {props.onForgotPassword && (
          <ForgotPassword onForgotPassword={props.onForgotPassword} />
        )}
      </div>
      <div className="mt-2">
        {/* <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="input w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-secondary-content focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        /> */}
        <input
          id="password"
          name="password"
          type="password"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          autoComplete="current-password"
          required
          className="input input-bordered input-sm w-full border-gray-300 bg-white text-black"
        />
      </div>
    </div>
  );
}
