import { useAuth } from '@/app/context/AuthContext/useAuth';
import React from 'react';
import Image from "next/legacy/image";
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';

interface IProps {
  isSignUp?: boolean;
}

export default function GoogleButton(props: IProps) {
  const { signInGoogle } = useAuth();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const onClick = () => {
    if (props.isSignUp) {
      trackEvent(analyticsInstance, 'auth_click_sign_up_google');
    } else {
      trackEvent(analyticsInstance, 'auth_click_sign_in_google');
    }

    signInGoogle();
  };

  return (
    <button
      onClick={onClick}
      className="btn btn-outline btn-block mt-1 flex-1 border-gray-800 text-gray-600 hover:bg-gray-800 hover:text-white"
    >
      <span className="mr-2">
        <Image height={24} width={24} alt="google" src="/Google_Logo.png" />
      </span>
      Google
    </button>
  );
}
