export function getGoogleAnalyticsClientId() {
  try {
    var cookie = {};
    document.cookie.split(';').forEach(function (el) {
      var splitCookie = el.split('=');
      var key = splitCookie[0].trim();
      var value = splitCookie[1];
      cookie[key] = value;
    });
    return cookie['_ga'].substring(6);
  } catch {
    return '';
  }
}

export function generateClientId(): string {
  const part1 = Math.floor(Math.random() * 900000000) + 100000000; // Generates a random number between 100000000 and 999999999
  const part2 = Math.floor(Math.random() * 9000000000) + 1000000000; // Generates another random number between 1000000000 and 9999999999
  return `${part1}.${part2}`;
}

function letterToNumber(letter: string): number {
  return letter.charCodeAt(0) - 64; // Convert letter to corresponding number (A=1, B=2, etc.)
}

export function parseClientIdFromLetterId(letterId: string): string {
  const [part1Letters, part2Letters] = letterId.split('-');

  const part1 = part1Letters.split('').map(letterToNumber).join('');
  const part2 = part2Letters.split('').map(letterToNumber).join('');

  return `${part1}.${part2}`;
}
