import firebaseApp from '../firebase-app';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { RunData } from '@/app/types/database/RunData';

const subscribeToRuns = (
  onChange: (runsMap: { [id: string]: RunData }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(doc(db, 'runs', auth.currentUser.uid), (doc) => {
    const runs = (doc.data() as { [id: string]: RunData }) || {};

    onChange(runs);
  });

  return unsub;
};

export default {
  subscribeToRuns
};
