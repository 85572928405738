export const getCleanText = (rawText) => {
  // Remove newlines and other formatting characters
  // let cleanText = rawText.replace(/(\r\n|\n|\r)/gm, ' ').trim();

  // Replace unicode quotes with asci quotes
  let cleanText = rawText.replace(/["“‘’”]/g, "'");

  // Replace dash asci dash
  cleanText = cleanText.replace(/(–)/g, '-');

  // Replace duplicate characters
  cleanText = cleanText.replace(/(\. \.)/g, '.');
  cleanText = cleanText.replace(/(' ')/g, "'");
  cleanText = cleanText.replace(/(, ,)/g, ',');
  cleanText = cleanText.replace(/(- -)/g, '-');
  cleanText = cleanText.replace(/(! !)/g, '!');

  // Remove erroneous apostrophes
  cleanText = cleanText.replace(/( ' )/g, '');

  // Remove unicode characters
  cleanText = cleanText.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '');

  // Replace more than one space with a single white space
  cleanText = cleanText.replace(/  +/g, ' ');

  cleanText = cleanText.trim();

  return cleanText;
};

export const getParagraphText = (image, paragraph) => {
  let para = '';
  let line = '';

  const lines = [];
  let lineWords = [];

  let hasLineBreak = false;
  for (const word of paragraph.words) {
    let wordText = '';
    let wordBoundingBox = getOcrBoundingBox(
      image,
      word['boundingBox']['normalizedVertices']
    );
    for (const symbol of word.symbols) {
      if (symbol.text) {
        line += symbol.text;
        wordText += symbol.text;
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'SPACE' // SPACE
      ) {
        line += ' ';
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'EOL_SURE_SPACE' // EOL_SURE_SPACE
      ) {
        line += ' ';
        para += line;
        line = '';
        hasLineBreak = true;
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'LINE_BREAK' // LINE_BREAK
      ) {
        para += line;
        line = '';
        hasLineBreak = true;
      }
    }

    lineWords.push({
      text: wordText,
      boundingBox: wordBoundingBox
    });

    if (hasLineBreak) {
      lines.push(lineWords);
      lineWords = [];
      hasLineBreak = false;
    }
  }

  if (lineWords.length > 0) {
    lines.push(lineWords);
  }

  return getCleanText(para);
};

export const getParagraphLines = (image, paragraph) => {
  let line = '';

  const lines = [];
  let lineWords = [];

  let hasLineBreak = false;
  for (const word of paragraph.words) {
    let wordText = '';
    let wordBoundingBox = getOcrBoundingBox(
      image,
      word['boundingBox']['normalizedVertices']
    );
    for (const symbol of word.symbols) {
      if (symbol.text) {
        line += symbol.text;
        wordText += symbol.text;
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'SPACE' // SPACE
      ) {
        line += ' ';
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'EOL_SURE_SPACE' // EOL_SURE_SPACE
      ) {
        hasLineBreak = true;
      }

      if (
        symbol.property &&
        symbol.property.detectedBreak &&
        symbol.property.detectedBreak.type === 'LINE_BREAK' // LINE_BREAK
      ) {
        hasLineBreak = true;
      }
    }

    lineWords.push({
      text: wordText,
      boundingBox: wordBoundingBox
    });

    if (hasLineBreak) {
      lines.push({ text: line, words: lineWords });
      line = '';
      lineWords = [];
      hasLineBreak = false;
    }
  }

  if (lineWords.length > 0) {
    lines.push({ text: line, words: lineWords });
  }

  return lines;
};

export const getParagraphWords = (image, paragraph) => {
  const words = [];

  for (const word of paragraph.words) {
    let text = '';
    for (const symbol of word.symbols) {
      if (symbol.text) {
        text += symbol.text;
      }
    }

    words.push({
      text,
      boundingBox: getOcrBoundingBox(
        image,
        word['boundingBox']['normalizedVertices']
      )
    });
  }

  return words;
};

export const getParagraphWordsMeanHeight = (paragraph) => {
  // Get the average height for all of the words in the paragraph

  try {
    const normalizedHeightValues = [];

    for (const word of paragraph.words) {
      const boundingBox = word['boundingBox']['normalizedVertices'];

      const normalizedHeight = boundingBox[2]['y'] - boundingBox[0]['y'];

      normalizedHeightValues.push(normalizedHeight);
    }

    return getArrayAverage(normalizedHeightValues);
  } catch (e) {
    console.log(e.toString());
    return 0;
  }
};

export const getOcrBoundingBox = (image, normalizedVertices) => {
  var fullImageParagraphLeftNormalized = normalizedVertices[0].x;
  var fullImageParagraphTopNormalized = normalizedVertices[0].y;
  var fullImageParagraphWidthNormalized =
    normalizedVertices[1].x - normalizedVertices[0].x;
  var fullImageParagraphHeightNormalized =
    normalizedVertices[2].y - normalizedVertices[1].y;
  var fullImageParagrapRightNormalized =
    fullImageParagraphLeftNormalized + fullImageParagraphWidthNormalized;
  var fullImageParagraphBottomNormalized =
    fullImageParagraphTopNormalized + fullImageParagraphHeightNormalized;

  const subImageLeftNormalized = image.bbox[0] / image.pageWidth;
  const subImageTopNormalized = image.bbox[1] / image.pageHeight;
  const subImageWidthNormalized =
    (image.bbox[2] - image.bbox[0]) / image.pageWidth;
  const subImageHeightNormalized =
    (image.bbox[3] - image.bbox[1]) / image.pageHeight;
  var subImageRightNormalized =
    subImageLeftNormalized + subImageWidthNormalized;
  var subImageBottomNormalized =
    subImageTopNormalized + subImageHeightNormalized;

  if (
    image.isFullPage ||
    (fullImageParagraphLeftNormalized > subImageLeftNormalized &&
      fullImageParagraphTopNormalized > subImageTopNormalized &&
      fullImageParagrapRightNormalized < subImageRightNormalized &&
      fullImageParagraphBottomNormalized < subImageBottomNormalized)
  ) {
    var subImageParagraphLeftNormalized =
      (fullImageParagraphLeftNormalized - subImageLeftNormalized) /
      subImageWidthNormalized;

    var subImageParagraphTopNormalized =
      (fullImageParagraphTopNormalized - subImageTopNormalized) /
      subImageHeightNormalized;

    var subImageParagraphWidthNormalized =
      fullImageParagraphWidthNormalized / subImageWidthNormalized;

    var subImageParagrapHeightNormalized =
      fullImageParagraphHeightNormalized / subImageHeightNormalized;

    var data = {
      left: parseFloat(subImageParagraphLeftNormalized.toFixed(4)),
      top: parseFloat(subImageParagraphTopNormalized.toFixed(4)),
      right:
        parseFloat(subImageParagraphLeftNormalized.toFixed(4)) +
        parseFloat(subImageParagraphWidthNormalized.toFixed(4)),
      bottom:
        parseFloat(subImageParagraphTopNormalized.toFixed(4)) +
        parseFloat(subImageParagrapHeightNormalized.toFixed(4)),
      width: parseFloat(subImageParagraphWidthNormalized.toFixed(4)),
      height: parseFloat(subImageParagrapHeightNormalized.toFixed(4))
    };

    return data;
  }

  return null;
};

export const getArrayAverage = (array) => {
  const sum = array.reduce((a, b) => a + b, 0);
  const avg = sum / array.length || 0;
  return avg;
};

export function doBoundingBoxesOverlap(box1: any, box2: any): boolean {
  // Calculate the right and bottom coordinates of the boxes
  let box1Right = box1.left + box1.width;
  let box1Bottom = box1.top + box1.height;
  let box2Right = box2.left + box2.width;
  let box2Bottom = box2.top + box2.height;

  // Check if box1 is to the right of box2 or box2 is to the right of box1
  if (box1.left >= box2Right || box2.left >= box1Right) {
    return false;
  }

  // Check if box1 is below box2 or box2 is below box1
  if (box1.top >= box2Bottom || box2.top >= box1Bottom) {
    return false;
  }

  // If none of the above conditions are met, the boxes overlap
  return true;
}

export const combineBoundingBoxes = (boundingBox1, boundingBox2) => {
  const minLeft = Math.min(boundingBox1.left, boundingBox2.left);

  const minTop = Math.min(boundingBox1.top, boundingBox2.top);

  const maxRight = Math.max(
    boundingBox1.left + boundingBox1.width,
    boundingBox2.left + boundingBox2.width
  );

  const maxBottom = Math.max(
    boundingBox1.top + boundingBox1.height,
    boundingBox2.top + boundingBox2.height
  );

  return {
    left: minLeft,
    top: minTop,
    width: parseFloat((maxRight - minLeft).toFixed(4)),
    height: parseFloat((maxBottom - minTop).toFixed(4))
  };
};

export const isBoundingBoxBeforeOtherBoundingBox = (
  boundingBox: any,
  otherBoundingBox: any
) => {
  // see if the other bounding box is above the bounding box
  // if it is above the bounding box, return false
  if (boundingBox.top + boundingBox.height < otherBoundingBox.top) {
    return true;
  }

  // see if the other bounding box is below the bounding box
  // if it is below the bounding box, return true
  if (otherBoundingBox.top + otherBoundingBox.height < boundingBox.top) {
    return false;
  }

  // otherwise, see if the other bounding box is the left of the bounding box
  // if it is to the left, return false, else return true
  const isLeft = boundingBox.left < otherBoundingBox.left;

  return isLeft;
};
