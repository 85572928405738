import React from 'react';

interface IProps {
  text: string;
}

export default function MethodDivider(props: IProps) {
  return (
    <div className="relative flex items-center py-5">
      <div className="flex-grow border-t border-gray-600"></div>
      <span className="mx-4 flex-shrink text-sm text-gray-600">
        {props.text}
      </span>
      <div className="flex-grow border-t border-gray-600"></div>
    </div>
  );
}
