import React, { useEffect, useState } from 'react';
import '@/assets/main.css';
import 'react-contexify/dist/ReactContexify.css';
import '@/assets/chrome-bug.css';
import './index.css';
import Modal from 'react-modal';
import { useRouter } from 'next/router';
import { DeckProvider } from '@/app/context/DeckContext/DeckProvider';
import { FeedbackProvider } from '@/app/context/FeedbackContext/FeedbackProvider';
import { AuthProvider } from '@/app/context/AuthContext/AuthProvider';
import { ModalProvider } from '@/app/context/ModalContext/ModalProvider';
import AuthModal from '@/components/common/auth-modal/AuthModal';
import { CustomerProvider } from '@/app/context/CustomerContext/CustomerProvider';
import { getCookieConsentValue } from 'react-cookie-consent';
import { PracticeTestProvider } from '@/app/context/PracticeTestContext/PracticeTestProvider';
import CustomErrorBoundary from '@/components/common/erorry-boundary/ErroryBoundary';
import { SettingsProvider } from '@/app/context/SettingsContext/SettingsProvider';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { onCLS, onFID, onLCP, onINP } from 'web-vitals/attribution';
import { AnalyticsProvider } from '@/app/context/AnalyticsContext/AnalyticsProvider';

// function gtag(event: any, name: any, params: any);
// function gtag(
//   event: any,
//   name: any,
//   params: any,
//   callback: (value: any) => void
// );

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [isConsented, setIsConsented] = useState(getCookieConsentValue());

  useEffect(() => {
    Modal.setAppElement('#app');
  }, []);

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  useEffect(() => {}, []);

  const onAcceptCookies = () => {
    setIsConsented('true');
  };

  const onDeclineCookies = () => {
    setIsConsented('false');
  };

  // function sendToGoogleAnalytics({ name, delta, value, id, attribution }) {
  //   try {
  //     const eventParams = {
  //       // Built-in params:
  //       value: delta, // Use `delta` so the value can be summed.
  //       // Custom params:
  //       metric_id: id, // Needed to aggregate events.
  //       metric_value: value, // Optional.
  //       metric_delta: delta // Optional.
  //     };

  //     switch (name) {
  //       case 'CLS':
  //         (eventParams as any).debug_target = attribution.largestShiftTarget;
  //         break;
  //       case 'FID':
  //         (eventParams as any).debug_target = attribution.eventTarget;
  //         break;
  //       case 'LCP':
  //         (eventParams as any).debug_target = attribution.element;
  //         break;
  //     }

  //     // Assumes the global `gtag()` function exists, see:
  //     // https://developers.google.com/analytics/devguides/collection/ga4
  //     gtag('event', name, eventParams);

  //     // https://web.dev/articles/debug-performance-in-the-field
  //     // https://web.dev/articles/vitals-ga4
  //   } catch {}
  // }

  // function sendINPToGoogleAnalytics({ name, value, id, attribution }) {
  //   // Destructure the attribution object:
  //   const { eventEntry, eventTarget, eventType, loadState } = attribution;

  //   // Get timings from the event timing entry:
  //   const {
  //     startTime,
  //     processingStart,
  //     processingEnd,
  //     duration,
  //     interactionId
  //   } = eventEntry;

  //   const eventParams = {
  //     // The page's INP value:
  //     metric_inp_value: value,
  //     // A unique ID for the page session, which is useful
  //     // for computing totals when you group by the ID.
  //     metric_id: id,
  //     // The event target (a CSS selector string pointing
  //     // to the element responsible for the interaction):
  //     metric_inp_event_target: eventTarget,
  //     // The type of event that triggered the interaction:
  //     metric_inp_event_type: eventType,
  //     // Whether the page was loaded when the interaction
  //     // took place. Useful for identifying startup versus
  //     // post-load interactions:
  //     metric_inp_load_state: loadState,
  //     // The time (in milliseconds) after page load when
  //     // the interaction took place:
  //     metric_inp_start_time: startTime,
  //     // When processing of the event callbacks in the
  //     // interaction started to run:
  //     metric_inp_processing_start: processingStart,
  //     // When processing of the event callbacks in the
  //     // interaction finished:
  //     metric_inp_processing_end: processingEnd,
  //     // The total duration of the interaction. Note: this
  //     // value is rounded to 8 milliseconds of granularity:
  //     metric_inp_duration: duration,
  //     // The interaction ID assigned to the interaction by
  //     // the Event Timing API. This could be useful in cases
  //     // where you might want to aggregate related events:
  //     metric_inp_interaction_id: interactionId
  //   };

  //   // Send to Google Analytics
  //   gtag('event', name, eventParams);
  // }

  // // Pass the reporting function to the web-vitals INP reporter:

  // useEffect(() => {
  //   // onCLS(sendToGoogleAnalytics);
  //   // onFID(sendToGoogleAnalytics);
  //   // onLCP(sendToGoogleAnalytics);
  //   // onINP(sendINPToGoogleAnalytics);
  // }, []);

  return (
    <>
      <div id="app" className="bg-base-100">
        <CustomErrorBoundary>
          <AuthProvider>
            <AnalyticsProvider>
              <SettingsProvider>
                <FeedbackProvider>
                  <DeckProvider>
                    <PracticeTestProvider>
                      <CustomerProvider>
                        <ModalProvider>
                          <Component {...pageProps} />
                          <AuthModal />
                          {/* <CookieConsent
                  onAccept={onAcceptCookies}
                  onDecline={onDeclineCookies}
                  declineButtonText={'Neccessary cookies only'}
                  enableDeclineButton
                  buttonClasses="!rounded-lg !bg-primary !text-base-content"
                  declineButtonClasses="!bg-gray-500 !rounded-lg"
                >
                  This website uses cookies to enhance the user experience. Some
                  cookies are required for Limbiks to function.
                </CookieConsent> */}
                        </ModalProvider>
                      </CustomerProvider>
                    </PracticeTestProvider>
                  </DeckProvider>
                </FeedbackProvider>
              </SettingsProvider>
            </AnalyticsProvider>
          </AuthProvider>
        </CustomErrorBoundary>
      </div>
      <SpeedInsights route={router.pathname} />
    </>
  );
}
