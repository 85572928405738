import router from 'next/router';

export const getDeckIdFromRouteMessy = () => {
  const url = window.location.href;
  const splitUrl = url.split('/app/');
  if (splitUrl.length > 1) {
    const id = splitUrl.slice(-1)[0];
    if (id) {
      return id;
    }
  }
  return null;
};

export const getDeckIdFromRoute = (): string | null => {
  // TODO: Find a cleaner way to use nextjs router to parse deck id reliably...

  if (!router.isReady) {
    // Uses this only on initial page load since router isn't loaded yet with params
    return getDeckIdFromRouteMessy();
  }
  const deckParam = router.query.deckId;
  if (deckParam) {
    if (typeof deckParam === 'string') {
      return deckParam;
    } else {
      return deckParam[0];
    }
  } else {
    return null;
  }
};

export const getPracticeTestIdFromRouteMessy = () => {
  const url = window.location.href;
  const splitUrl = url.split('/practice/');
  if (splitUrl.length > 1) {
    const id = splitUrl.slice(-1)[0];
    if (id) {
      return id;
    }
  }
  return null;
};

export const getPracticeTestIdFromRoute = (): string | null => {
  // TODO: Find a cleaner way to use nextjs router to parse deck id reliably...

  if (!router.isReady) {
    // Uses this only on initial page load since router isn't loaded yet with params
    return getDeckIdFromRouteMessy();
  }
  const testParam = router.query.testId;
  if (testParam) {
    if (typeof testParam === 'string') {
      return testParam;
    } else {
      return testParam[0];
    }
  } else {
    return null;
  }
};
