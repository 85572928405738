import { IndexType } from '@/app/constants/IndexType';
import { Note } from '@/app/types/AnkiConnect/Note';
import { DeckMedia } from '@/app/types/Generator/DeckMedia';
import { CardData, DeckData } from '@/app/types/database/DeckData';
import { getPaddedNumberString } from '@/app/utils/stringUtils';

const underlineRegex = /(_{3,})/g;

const replaceAt = function (
  string: string,
  start: number,
  end: number,
  replaceWith: string
) {
  return string.substring(0, start) + replaceWith + string.substring(end);
};

const isCloze = (question: string) => {
  return (
    question.match(underlineRegex) && question.match(underlineRegex).length > 0
  );
};

const buildClozeText = (question: string, answer: string) => {
  const matches = question.match(underlineRegex);
  const answers = answer.split(', ');

  let clozeText = question;

  let answerIndex = 0;

  for (let matchIndex = 0; matchIndex < matches.length; matchIndex++) {
    const match = matches[matchIndex];

    const startIndex = clozeText.indexOf(match);
    const endIndex = startIndex + match.length;

    let answer = '';

    if (answers.length > answerIndex) {
      // If we reach the end of the matches and their still remains answer in the answer array...
      // We should append them all to this answer
      if (matchIndex + 1 >= matches.length) {
        answer = answers.slice(answerIndex, answers.length).join(', ');
      } else {
        answer = answers[answerIndex];
        answerIndex += 1;
      }
    }

    answer = `{{c1::${answer}}}`;

    clozeText = replaceAt(clozeText, startIndex, endIndex, answer);
  }

  return clozeText;
};

type ImageOcclusionBoundingBox = {
  left: number;
  top: number;
  width: number;
  height: number;
};

const buildOcclusionText = (boundingBoxes: ImageOcclusionBoundingBox[]) => {
  let occlusionTexts = boundingBoxes.map((boundingBox, index) => {
    return `{{c${index + 1}::image-occlusion:rect:left=${
      boundingBox.left
    }:top=${boundingBox.top}:width=${boundingBox.width}:height=${
      boundingBox.height
    }:oi=1}}`;
  });

  return occlusionTexts.join(' ');
};

export const buildAnkiNotes = (
  deckName: string,
  indexType: IndexType,
  isImageOcclusion: boolean,
  deckData: DeckData,
  deckMedia: DeckMedia[]
): Note[] => {
  const hasTags = deckData.cards.some((t) => t.index > 0);

  if (isImageOcclusion) {
    // group together all of the cards that are the same question (question is media file name)
    const groupedOcclusions = deckData.cards.reduce((acc, curr) => {
      if (!acc[curr.question]) acc[curr.question] = []; //If this type wasn't previously stored
      acc[curr.question].push(JSON.parse(curr.answer));
      return acc;
    }, {} as { string: ImageOcclusionBoundingBox[] });

    const cards = Object.entries(groupedOcclusions).map(
      ([question, answers]) => {
        const occlusionMedia = deckMedia.find((t) => t.filename === question);

        const fields = {
          Occlusion: buildOcclusionText(answers),
          Image: '',
          Header: '',
          'Back Extra': '',
          Comments: ''
        };

        const picture = {
          url: occlusionMedia.signedUrl,
          filename: question,
          fields: ['Image']
        };

        return {
          deckName,
          modelName: 'Image Occlusion',
          fields,
          picture: [picture],
          tags: hasTags
            ? [indexType + '_' + getPaddedNumberString(occlusionMedia.index, 2)]
            : []
        };
      }
    );

    return cards;
  }

  return deckData.cards.map((t) => {
    const cloze = isCloze(t.question);
    const fields: any = {};
    if (cloze) {
      fields.text = buildClozeText(t.question, t.answer);
    } else {
      fields.front = t.question;
      fields.back = t.answer;
    }

    return {
      deckName,
      modelName: cloze ? 'Cloze' : 'Basic',
      fields,
      tags: hasTags ? [indexType + '_' + getPaddedNumberString(t.index, 2)] : []
    };
  });
};
