import firebaseApp from '@/app/database/firebase-app';
import { DeckMedia } from '@/app/types/Generator/DeckMedia';
import { GeneratorOptions } from '@/app/types/Generator/GeneratorOptions';
import { OcclusionData } from '@/app/types/Generator/OcclusionData';
import { ParsedContent } from '@/app/types/Generator/ParsedContent';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const generate = async (deckId: string, options: GeneratorOptions) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(`/api/decks/${deckId}/generate`, options, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

const saveContent = async (deckId: string, parsedContent: ParsedContent[]) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(`/api/decks/${deckId}/saveContent`, parsedContent, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

const getMedia = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  const result = await axios.get<DeckMedia[]>(`/api/decks/${deckId}/media`, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });

  return result.data;
};

const getAvailableOcclusions = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  const result = await axios.get<OcclusionData[]>(
    `/api/decks/${deckId}/availableOcclusions`,
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );

  return result.data;
};

const saveLearningObjectives = async (deckId: string, content: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/saveLearningObjectives`,
    { content },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const saveCustomInstructions = async (deckId: string, content: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/saveCustomInstructions`,
    { content },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const share = async (deckId: string, shareWithEmail: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/share`,
    { email: shareWithEmail },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const unShare = async (deckId: string, shareWithEmail: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/unShare`,
    { email: shareWithEmail },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const acceptShare = async (deckId: string, folderId?: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/acceptShare`,
    {
      folderId
    },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const rejectShare = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(
    `/api/decks/${deckId}/rejectShare`,
    {},
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const preprocess = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  await axios.get<string[]>(`/api/decks/${deckId}/preprocess`, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

type AnkiDownloadResponse = {
  success: boolean;
  signedUrl: string;
};

const ankiDownload = async (deckId: string, deckName: string, notes: any[]) => {
  const auth = getAuth(firebaseApp);

  return await axios.post<AnkiDownloadResponse>(
    `/api/decks/${deckId}/ankiPackage`,
    {
      deckName,
      notes
    },
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );
};

const getPreviewImages = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  return await (
    await axios.get<string[]>(`/api/decks/${deckId}/previewImages`, {
      headers: { token: await auth.currentUser.getIdToken() }
    })
  ).data;
};

// GET - Gets an upload url for the deck id
const getUploadUrl = async (deckId: string) => {
  const auth = getAuth(firebaseApp);

  return await (
    await axios.get<string>(`/api/decks/${deckId}/upload`, {
      headers: { token: await auth.currentUser.getIdToken() }
    })
  ).data;
};

const upload = async (
  uploadUrl: string,
  file: any,
  onUploadProgress: (percent: number) => void
) => {
  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type
    },
    onUploadProgress: (progressEvent: any) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      onUploadProgress(percentCompleted);
    }
  });
};

export default {
  ankiDownload,
  preprocess,
  getUploadUrl,
  getPreviewImages,
  upload,
  generate,
  saveContent,
  saveLearningObjectives,
  saveCustomInstructions,
  share,
  unShare,
  acceptShare,
  rejectShare,
  getMedia,
  getAvailableOcclusions
};
