import axios from 'axios';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const getIpCountry = async () => {
  const response = await axios.get<string>(`/api/location/country`, {
    timeout: 0
  });

  return response.data;
};

export default {
  getIpCountry
};
