import { useContext } from 'react';
import { PracticeTestContext } from './PracticeTestProvider';

export const usePracticeTest = () => {
  const context = useContext(PracticeTestContext);
  if (context === undefined) {
    throw new Error(
      `useFeedback must be used within a PracticeContextProvider.`
    );
  }
  return context;
};
