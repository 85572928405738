import firebaseApp from '@/app/database/firebase-app';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const getSimpleDistractors = async (answer: string) => {
  const auth = getAuth(firebaseApp);
  const response = await axios.get<string[]>(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/distractors`,
    {
      headers: { authorization: await auth.currentUser.getIdToken() },
      timeout: 0,
      params: {
        answer
      }
    }
  );

  return response.data;
};

const getEnhancedQuestion = async (question: string, answer: string) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.get<{ question: string; answer: string }>(
    process.env.NEXT_PUBLIC_GET_ENHANCED_QUESTION_FUNCTION_URL,
    {
      headers: { authorization: await auth.currentUser.getIdToken() },
      timeout: 0,
      params: {
        question,
        answer
      }
    }
  );

  return response.data;
};

const getEnhancedDistractors = async (
  question: string,
  answer: string,
  useGpt4: boolean,
  language?: string
) => {
  const auth = getAuth(firebaseApp);

  try {
    const response = await axios.get<string[]>(
      process.env.NEXT_PUBLIC_GET_DISTRACTORS_FUNCTION_URL,
      {
        headers: { authorization: await auth.currentUser.getIdToken() },
        timeout: 0,
        params: {
          question,
          answer,
          m: useGpt4 ? 'y' : 'x',
          language
        }
      }
    );

    return response.data;
  } catch (e) {
    const response = await axios.get<string[]>('/external/api/distractors', {
      headers: { authorization: await auth.currentUser.getIdToken() },
      timeout: 0,
      params: {
        question,
        answer
      }
    });

    return response.data;
  }
};

export default {
  getSimpleDistractors,
  getEnhancedDistractors,
  getEnhancedQuestion
};
