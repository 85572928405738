import {
  getAuth,
  User,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithCustomToken
} from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import nookies from 'nookies';
import { useRouter } from 'next/router';

import { Url } from 'url';

import firebaseApp from '@/app/database/firebase-app';
import 'firebase/compat/auth';
import useFacebookTracking from '@/app/hooks/useFacebookTracking';
import AccessKeysDB from '@/app/database/accessKeys/AccessKeysDB';
import AccessKeyAPI from '@/app/api/AccessKeyAPI/AccessKeyAPI';
import AccountAPI from '@/app/api/AccountAPI/AccountAPI';
import { supportedCountries } from '@/app/constants/SupportedCountries';
import LocationAPI from '@/app/api/LocationAPI/LocationAPI';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

export const AuthContext = createContext<{
  user: User | null;
  token: string | null;
  isAuthShowing: boolean;
  isSignUpShowing: boolean;
  isProEligible: boolean;
  accessKey: string;
  rotateAccessKey: () => void;
  showAuth: (isSignUp: boolean, redirectUrl?: Partial<Url>) => void;
  redirectUrl: Partial<Url> | null;
  hideAuth: () => void;
  verifyEmail: () => void;
  signInGoogle: () => void;
  sendPasswordReset: (email: string) => Promise<boolean>;
  signUpEmailAndPassword: (email: string, password: string) => Promise<boolean>;
  signInEmailAndPassword: (email: string, password: string) => Promise<boolean>;
  signInOnBehalf: (uid: string) => Promise<void>;
  signOut: () => void;
}>({
  user: null,
  token: null,
  isAuthShowing: false,
  isSignUpShowing: false,
  isProEligible: false,
  accessKey: '',
  rotateAccessKey: () => null,
  showAuth: (isSignUp: boolean, redirectUrl?: Partial<Url>) => null,
  redirectUrl: null,
  hideAuth: () => null,
  verifyEmail: () => null,
  signInGoogle: () => null,
  sendPasswordReset: async (email: string) => false,
  signUpEmailAndPassword: async (email: string, password: string) => false,
  signInEmailAndPassword: async (email: string, password: string) => false,
  signInOnBehalf: (uid: string) => null,
  signOut: () => null
});

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [manualLogin, setManualLogin] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState<Partial<Url> | null>(null);

  const [isAuthShowing, setIsAuthShowing] = useState(false);
  const [isSignUpShowing, setIsSignUpShowing] = useState(false);

  const [accessKey, setAccessKey] = useState('');

  const auth = getAuth(firebaseApp);
  const router = useRouter();
  const facebookTracking = useFacebookTracking();

  const [isProEligible, setIsProEligible] = useState(false);

  const loadCountry = async () => {
    const countryCode = await LocationAPI.getIpCountry();

    if (countryCode in supportedCountries) {
      setIsProEligible(true);
    } else {
      setIsProEligible(false);
    }
  };

  useEffect(() => {
    loadCountry();
  }, []);

  useEffect(() => {
    // this variable, ManualLogin will only be true when signing into the app
    if (manualLogin && user && !user.isAnonymous) {
      setManualLogin(false);

      if (redirectUrl) {
        router.push(redirectUrl);
        setRedirectUrl(null);
      } else {
        router.push('/app');
      }
      // redirectUrl should only not have a value on initial page load
    } else if (
      user &&
      !user.isAnonymous &&
      !redirectUrl &&
      router.pathname === '/'
    ) {
      router.replace('/app');
    }
  }, [user]);

  useEffect(() => {
    return auth.onIdTokenChanged(async (user) => {
      if (!user) {
        nookies.set(undefined, 'token', '', { path: '/' });
      } else {
        const token = await user.getIdToken();
        setToken(token);
        nookies.set(undefined, 'token', token, { path: '/' });
      }

      if (!user || user.isAnonymous) {
        setUser(null);

        if (router.pathname.includes('/app') && !isSigningOut) {
          const redirectUrl = {
            // can't use router pathname because the slug is screwed up
            pathname: window.location.pathname,
            query: router.query
          };

          await router.replace('/');

          if (router.pathname.includes('/app/account/key')) {
            // show sign up form by default for anki add-on visitors
            showAuth(true, redirectUrl);
          } else {
            showAuth(false, redirectUrl);
          }
        } else if (isSigningOut) {
          setIsSigningOut(false);
        }
      } else {
        setUser(user);
        hideAuth();
      }
    });
  }, [isSigningOut]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    if (token) {
      try {
        const unsubscribe = AccessKeysDB.subscribeToAccessKey((accessKey) => {
          if (accessKey) {
            setAccessKey(accessKey.key);
          } else {
            // If No access key is returned then let's create one for the first time
            rotateAccessKey();
          }
        });

        return () => unsubscribe();
      } catch (e) {}
    } else {
      setAccessKey('');
    }
  }, [token]);

  const rotateAccessKey = async () => {
    await AccessKeyAPI.rotateAccessKey();
  };

  const showAuth = (isSignUp: boolean, redirectUrl?: Partial<Url>) => {
    setIsSignUpShowing(isSignUp);
    setIsAuthShowing(true);
    setRedirectUrl(redirectUrl || null);
  };

  const hideAuth = () => {
    setIsAuthShowing(false);
  };

  const verifyEmail = async () => {
    await sendEmailVerification(user);
  };

  const sendPasswordReset = async (email: string) => {
    const auth = getAuth();
    const success = await sendPasswordResetEmail(auth, email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error);
        setManualLogin(false);

        try {
          // because of circular dependency, we don't use analytics provider
          const analytics = getAnalytics();
          logEvent(analytics, 'send_password_reset_failed');
        } catch {}

        return false;
        // ..
      });

    if (success) {
      try {
        // because of circular dependency, we don't use analytics provider
        const analytics = getAnalytics();
        logEvent(analytics, 'send_password_reset');
      } catch {}
      return true;
    }

    return false;
  };

  const signUpEmailAndPassword = async (email: string, password: string) => {
    const auth = getAuth(firebaseApp);

    setManualLogin(true);

    await createUserWithEmailAndPassword(auth, email, password).catch(
      (error) => {
        console.error(error);
        window.alert(error);
        setManualLogin(false);

        try {
          // because of circular dependency, we don't use analytics provider
          const analytics = getAnalytics();
          logEvent(analytics, 'sign_up_failed', {
            method: 'Email',
            errorMessage: error.toString()
          });
        } catch {}

        return false;
      }
    );

    facebookTracking.trackSignUp();

    try {
      // because of circular dependency, we don't use analytics provider
      const analytics = getAnalytics();
      logEvent(analytics, 'sign_up', { method: 'Email' });
    } catch {}

    return true;
  };

  const signInEmailAndPassword = async (email: string, password: string) => {
    const auth = getAuth(firebaseApp);

    setManualLogin(true);

    const result = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      console.error(error);
      setManualLogin(false);

      try {
        // because of circular dependency, we don't use analytics provider
        const analytics = getAnalytics();
        logEvent(analytics, 'login_failed', {
          method: 'Email',
          errorMessage: error.toString()
        });
      } catch {}

      return false;
    });

    if (result) {
      try {
        // because of circular dependency, we don't use analytics provider
        const analytics = getAnalytics();
        logEvent(analytics, 'login', { method: 'Email' });
      } catch {}
      return true;
    }

    return false;
  };

  const signInOnBehalf = async (uid: string) => {
    const customToken = await AccountAPI.getToken(uid);

    await signInWithCustomToken(auth, customToken).catch((error) => {
      console.error(error);
      setManualLogin(false);
    });
  };

  const signInGoogle = async () => {
    const auth = getAuth(firebaseApp);

    const provider = new GoogleAuthProvider();

    setManualLogin(true);

    if (user?.isAnonymous) {
      facebookTracking.trackSignUp();
    }

    await signInWithPopup(auth, provider).catch((error) => {
      console.error(error);
      setManualLogin(false);

      try {
        // because of circular dependency, we don't use analytics provider
        const analytics = getAnalytics();
        logEvent(
          analytics,
          user?.isAnonymous ? 'sign_up_failed' : ('login_failed' as string),
          {
            method: 'Google',
            errorMessage: error.toString()
          }
        );
      } catch {}
    });

    try {
      // because of circular dependency, we don't use analytics provider
      const analytics = getAnalytics();
      logEvent(analytics, user?.isAnonymous ? 'sign_up' : ('login' as string), {
        method: 'Google'
      });
    } catch {}
  };

  const signOut = async () => {
    setIsSigningOut(true);
    await router.replace('/');
    await auth.signOut();

    try {
      // because of circular dependency, we don't use analytics provider
      const analytics = getAnalytics();
      logEvent(analytics, 'sign_out');
    } catch {}
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthShowing,
        isSignUpShowing,
        redirectUrl,
        accessKey,
        isProEligible,
        rotateAccessKey,
        showAuth,
        hideAuth,
        signInGoogle,
        signOut,
        verifyEmail,
        token,
        signUpEmailAndPassword,
        signInEmailAndPassword,
        sendPasswordReset,
        signInOnBehalf
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
