// Import fsrs.js library
import { CardSchedule } from '@/app/types/database/CardSchedule';
import * as fsrsJs from 'fsrs.js';

export const getUpdatedCardSchedule = (
  cardSchedule: CardSchedule,
  rating: fsrsJs.Rating
) => {
  let fsrs = new fsrsJs.FSRS();
  let scheduling_cards = fsrs.repeat(cardSchedule, new Date());
  return {
    deckId: cardSchedule.deckId,
    cardId: cardSchedule.cardId,
    ...scheduling_cards[rating].card
  } as CardSchedule;
};

export const getPossbleDueDates = (currentCardSchedule: CardSchedule) => {
  let fsrs = new fsrsJs.FSRS();

  let rating = fsrsJs.Rating;

  let scheduling_cards = fsrs.repeat(currentCardSchedule, new Date());

  //Get the new state of card for each rating:
  const againDueDate = scheduling_cards[rating.Again].card.due;
  const hardDueDate = scheduling_cards[rating.Hard].card.due;
  const goodDueDate = scheduling_cards[rating.Good].card.due;
  const easyDueDate = scheduling_cards[rating.Easy].card.due;

  return {
    [rating.Again]: againDueDate,
    [rating.Hard]: hardDueDate,
    [rating.Good]: goodDueDate,
    [rating.Easy]: easyDueDate
  };
};

export const getDisplayTime = (date: Date) => {
  const now = new Date();
  const diffInMilliseconds = Math.abs(date.getTime() - now.getTime());

  const diffInMinutes = Math.ceil(diffInMilliseconds / 60000);
  const diffInHours = Math.ceil(diffInMilliseconds / 3600000);
  const diffInDays = Math.ceil(diffInMilliseconds / 86400000);

  if (diffInMinutes < 60) {
    return '<' + Math.max(1, diffInMinutes) + 'm';
  } else if (diffInHours < 12) {
    return '<' + diffInHours + 'h';
  } else {
    return diffInDays + 'd';
  }
};
