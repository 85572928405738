import firebaseApp from '../firebase-app';
import {
  doc,
  getFirestore,
  onSnapshot,
  runTransaction,
  deleteField,
  setDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getUtcNowTimestamp } from '@/app/utils/timeUtils';
import { PracticeTestMetadata } from '@/app/types/database/PracticeTestMetadata';
import { PracticeTestData } from '@/app/types/database/PracticeTestData';

const create = async (practiceTest: PracticeTestMetadata): Promise<string> => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  practiceTest.created = getUtcNowTimestamp();

  await setDoc(
    doc(db, 'practiceTests', auth.currentUser.uid),
    {
      [practiceTest.id]: practiceTest
    },
    { merge: true }
  );

  return practiceTest.id;
};

const updatePracticeTest = async (
  practiceTestId: string,
  practiceTest: Partial<PracticeTestMetadata>
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'practiceTests', auth.currentUser.uid),
    {
      [practiceTestId]: practiceTest
    },
    { merge: true }
  );
};

const updateData = async (practiceTestId: string, data: PracticeTestData) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  await runTransaction(db, async (transaction) => {
    transaction.set(
      doc(db, 'practiceTests', auth.currentUser.uid, 'data', practiceTestId),
      data,
      { merge: false }
    );
  });
};

const remove = async (practiceTestId: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  await runTransaction(db, async (transaction) => {
    // Delete Metadata
    transaction.set(
      doc(db, 'practiceTests', auth.currentUser.uid),
      {
        [practiceTestId]: deleteField()
      },
      { merge: true }
    );

    // Delete Cards
    transaction.delete(
      doc(db, 'decks', auth.currentUser.uid, 'data', practiceTestId)
    );
  });
};

// Deck cards are stored as a subcollection
const subscribeToPracticeTests = (
  onChange: (practiceTestsMap: { [id: string]: PracticeTestMetadata }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(
    doc(db, 'practiceTests', auth.currentUser.uid),
    (doc) => {
      const practiceTests =
        (doc.data() as { [id: string]: PracticeTestMetadata }) || {};

      onChange(practiceTests);
    }
  );

  return unsub;
};

const subscribeToData = (
  practiceTestId: string,
  onChange: (data: PracticeTestData) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(
    doc(db, 'practiceTests', auth.currentUser.uid, 'data', practiceTestId),
    (doc) => {
      const practiceTestData = doc.data();
      onChange((practiceTestData as PracticeTestData) || null);
    }
  );

  return unsub;
};

export default {
  create,
  remove,
  updatePracticeTest,
  updateData,
  subscribeToPracticeTests,
  subscribeToData
};
