import { DeckStatus } from '@/app/constants/DeckStatus';
import firebaseApp from '../firebase-app';
import { v4 as uuidv4 } from 'uuid';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { DeckMetadata } from '@/app/types/database/DeckMetadata';

// Deck cards are stored as a subcollection
const subscribeToQueue = (
  onChange: (deckMap: { [id: string]: DeckMetadata }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(
    doc(db, 'shareQueue', auth.currentUser.email),
    (doc) => {
      const decks = (doc.data() as { [id: string]: DeckMetadata }) || {};

      const validDecks = {};

      Object.keys(decks).forEach((deckId) => {
        const deck = decks[deckId];
        if (
          deck &&
          typeof deck.created === 'object' &&
          typeof deck.cardCount === 'number' &&
          typeof deck.name === 'string' &&
          typeof deck.status === 'string'
        ) {
          validDecks[deckId] = deck;
        }
      });

      onChange(validDecks);
    }
  );

  return unsub;
};

export default {
  subscribeToQueue
};
