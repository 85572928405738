import { Note } from '@/app/types/AnkiConnect/Note';
import { RequestPermissionResponse } from '@/app/types/AnkiConnect/RequestPermissionResponse';
import { DeckData } from '@/app/types/database/DeckData';
import axios from 'axios';

const ping = async (): Promise<boolean> => {
  try {
    await fetch('http://127.0.0.1:8765', { mode: 'no-cors' });
    return true;
  } catch {
    return false;
  }
};

const requestPermission = async (): Promise<boolean> => {
  const response = await fetch('http://127.0.0.1:8765', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      action: 'requestPermission',
      version: 6
    })
  });

  const responseData: RequestPermissionResponse = await response.json();

  return responseData.result.permission === 'granted';
};

const createDeck = async (deckName: string): Promise<boolean> => {
  const result = await axios.post('http://127.0.0.1:8765', {
    action: 'createDeck',
    version: 6,
    params: {
      deck: deckName
    }
  });

  return result.status === 200;
};

const createNotes = async (notes: Note[]): Promise<boolean> => {
  const result = await axios.post('http://127.0.0.1:8765', {
    action: 'addNotes',
    version: 6,
    params: {
      notes
    }
  });

  return result.status === 200;
};

const downloadAnkiPackage = async (
  deckName: string,
  deckData: DeckData
): Promise<boolean> => {
  await axios
    .post(
      `${process.env.NEXT_PUBLIC_GET_ANKI_PACKAGE_FUNCTION_URL}`,
      {
        deckName,
        flashcards: deckData.cards.map((card) => ({
          question: card.question,
          answer: card.answer
        }))
      },
      {
        timeout: 0,
        responseType: 'text' /* since the response is a base64 string */
      }
    )
    .then((response) => {
      const binaryString = window.atob(response.data); // decode base64
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      // proceed with download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${deckName}.apkg`);
      document.body.appendChild(link);
      link.click();
    });
  return true;
};

export default {
  ping,
  requestPermission,
  createDeck,
  createNotes,
  downloadAnkiPackage
};
