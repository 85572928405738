import React, { createContext, useEffect, useState } from 'react';
import { useAuth } from '../AuthContext/useAuth';
import {
  Analytics,
  setUserId,
  logEvent,
  isSupported,
  initializeAnalytics,
  setUserProperties,
  getAnalytics
} from 'firebase/analytics';
import firebaseApp from '@/app/database/firebase-app';
import { useRouter } from 'next/router';

interface IAnalyticsContext {
  analyticsInstance: Analytics;
  trackEvent: typeof logEvent;
  setProperties: (properties: any) => void;
}

export const AnalyticsContext = createContext<IAnalyticsContext>(null);

export const AnalyticsProvider = (props) => {
  const [privateAnalytics, setPrivateAnalytics] = useState<Analytics | null>(
    null
  );

  const { user } = useAuth();
  const router = useRouter();

  const getInitializedAnalytics = async () => {
    if (privateAnalytics !== null) {
      return privateAnalytics;
    }

    if (typeof window !== 'undefined') {
      const isAnalyticsSupported = await isSupported();
      if (isAnalyticsSupported) {
        try {
          const initializedAnalytics = initializeAnalytics(firebaseApp, {
            config: {
              send_page_view: false
            }
          });

          setPrivateAnalytics(initializedAnalytics);
          return initializedAnalytics;
        } catch {
          try {
            // initialize call will have exception if its already been initialized for some reason
            // in that rare case, lets just get the existing instance and return that
            const existingAnalytics = getAnalytics();

            return existingAnalytics;
          } catch {
            return null;
          }
        }
      }
    }

    return null;
  };

  useEffect(() => {
    // Call this to trigger analytics to populate on load
    getInitializedAnalytics();
  }, []);

  const configureUserId = async (userId: string) => {
    const analytics = await getInitializedAnalytics();
    if (analytics) {
      setUserId(analytics, userId);

      if (userId) {
        trackEvent(analytics, 'authenticated');
      }
    }
  };

  useEffect(() => {
    if (user && !user.isAnonymous) {
      configureUserId(user.uid);
    } else {
      configureUserId(null);
    }
  }, [user]);

  useEffect(() => {
    if (privateAnalytics) {
      const handleRouteChange = (url: string) => {
        logEvent(privateAnalytics, 'page_view', {
          page_path: url,
          page_location: document?.location.href,
          page_title: document?.title
        });
      };

      router.events.on('routeChangeStart', handleRouteChange);

      // force call this on page load
      handleRouteChange(router.pathname);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }
  }, [privateAnalytics]);

  // Call track even from custom function instead of global logEvent
  // to ensure we always have an initialized instance of analytics
  // and we also avoid compilation errors
  const trackEvent: typeof logEvent = async (
    instance,
    eventName,
    eventParams,
    options
  ) => {
    try {
      if (instance) {
        logEvent(instance, eventName, eventParams, options);
      } else {
        const analytics = await getInitializedAnalytics();
        if (analytics) {
          logEvent(analytics, eventName, eventParams, options);
        }
      }
    } catch (e) {
      // continue
      // Don't want to break app because of failed event tracking
    }
  };

  const setProperties = async (properties: any) => {
    try {
      if (privateAnalytics) {
        setUserProperties(privateAnalytics, properties);
      } else {
        const analytics = await getInitializedAnalytics();
        if (analytics) {
          setUserProperties(analytics, properties);
        }
      }
    } catch (e) {
      console.log(e);
      // continue
      // Don't want to break app because of failed event tracking
    }
  };

  const value = {
    analyticsInstance: privateAnalytics,
    trackEvent,
    setProperties
  };

  return <AnalyticsContext.Provider value={value} {...props} />;
};
