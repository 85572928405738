import Modal from '@/components/common/modal/Modal';
import Feedback from '@/components/common/modal/feedback/Feedback';
import Help from '@/components/common/modal/help/Help';
import React, { createContext, useState } from 'react';
import Settings from '@/components/common/modal/settings/Settings';
import { MergeDecks } from '@/components/common/modal/merge-decks/MergeDecks';
import { ChangeFolder } from '@/components/common/modal/change-folder/ChangeFolder';
import { CreateFolder } from '@/components/common/modal/create-folder/CreateFolder';
import { RenameFolder } from '@/components/common/modal/rename-folder/RenameFolder';
import AnkiError from '@/components/common/modal/anki-error/AnkiError';
import MemorizeHelp from '@/components/common/modal/memorize-help/MemorizeHelp';
import { EditOcclusion } from '@/components/common/modal/edit-occlusion/EditOcclusion';
import LimbiksKey from '@/components/common/modal/limbiks-key/LimbiksKey';
import UpgradeSuccess from '@/components/common/modal/upgrade-succes/UpgradeSuccess';
import Upgrade from '@/components/common/modal/upgrade/Upgrade';

interface IModalContext {
  openModal: (modal: keyof typeof Modals, selectedItem?: any) => void;
  resetModals: () => void;
}

export const ModalContext = createContext<IModalContext>(null);

export const Modals = {
  feedback: 'feedback',
  settings: 'settings',
  help: 'help',
  merge: 'merge',
  createFolder: 'createFolder',
  renameFolder: 'renameFolder',
  deleteFolder: 'deleteFolder',
  changeFolder: 'changeFolder',
  ankiError: 'ankiError',
  editOcclusion: 'editOcclusion',
  memorizeHelp: 'memorizeHelp',
  limbiksKey: 'limbiksKey',
  upgrade: 'upgrade',
  upgradeSuccess: 'upgradeSuccess'
};

export function ModalProvider({ children }: any) {
  const [activeModal, setActiveModal] = useState<keyof typeof Modals | null>(
    null
  );

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const openModal = (modal: keyof typeof Modals, selectedItem?: any) => {
    if (selectedItem) {
      setSelectedItem(selectedItem);
    } else {
      setSelectedItem(null);
    }

    setActiveModal(modal);
  };

  const resetModals = () => {
    setActiveModal(null);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        resetModals
      }}
    >
      <Modal
        wide={
          activeModal === 'ankiError' ||
          activeModal === 'memorizeHelp' ||
          activeModal === 'help' ||
          activeModal === 'editOcclusion' ||
          activeModal === 'upgradeSuccess'
        }
        open={!!activeModal}
        onClickAway={resetModals}
      >
        {activeModal === 'feedback' && <Feedback onClose={resetModals} />}
        {activeModal === 'help' && <Help onClose={resetModals} />}
        {activeModal === 'settings' && <Settings onClose={resetModals} />}
        {activeModal === 'merge' && (
          <MergeDecks selectedDeckId={selectedItem} onClose={resetModals} />
        )}
        {activeModal === 'changeFolder' && (
          <ChangeFolder selectedDeckId={selectedItem} onClose={resetModals} />
        )}
        {activeModal === 'createFolder' && (
          <CreateFolder onClose={resetModals} />
        )}
        {activeModal === 'editOcclusion' && (
          <EditOcclusion
            onClose={resetModals}
            selectedFilename={selectedItem}
          />
        )}
        {activeModal === 'renameFolder' && (
          <RenameFolder selectedFolderId={selectedItem} onClose={resetModals} />
        )}
        {activeModal === 'ankiError' && <AnkiError onClose={resetModals} />}
        {activeModal === 'memorizeHelp' && (
          <MemorizeHelp onClose={resetModals} />
        )}
        {activeModal === 'limbiksKey' && <LimbiksKey onClose={resetModals} />}
        {activeModal === 'upgrade' && (
          <Upgrade target={selectedItem} onClose={resetModals} />
        )}
        {activeModal === 'upgradeSuccess' && (
          <UpgradeSuccess onClose={resetModals} />
        )}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
}
