import FeedbackDB from '@/app/database/feedback/FeedbackDB';
import React, { createContext, useEffect, useState } from 'react';
import { useAuth } from '../AuthContext/useAuth';
import { Surveys } from '@/app/types/database/Surveys';
import { v4 as uuidv4 } from 'uuid';
import {
  PracticeTestData,
  PracticeTestQuestion
} from '@/app/types/database/PracticeTestData';
import { PracticeTestMetadata } from '@/app/types/database/PracticeTestMetadata';
import DistractorsAPI from '@/app/api/DistractorsAPI/DistractorsAPI';
import DecksDB from '@/app/database/decks/DecksDB';
import { DeckData } from '@/app/types/database/DeckData';
import { shuffle } from '@/app/utils/arrayUtils';
import PracticeTestDB from '@/app/database/practiceTest/PracticeTestDB';
import {
  getDeckIdFromRoute,
  getPracticeTestIdFromRoute
} from '@/app/utils/routeUtils';
import { useRouter } from 'next/router';
import { useFeedback } from '../FeedbackContext/useFeedback';
import { useDeck } from '../DeckContext/useDeck';
import GenericResponseAPI from '@/app/api/GenericResponseAPI/GenericResponseAPI';
import { PredefinedPromptKey } from '@/app/constants/PredefinedPrompt';

interface IPracticeTestContext {
  createPracticeTest: (
    name: string,
    decks: string[],
    questionDistribution: 'even' | 'weighted' | 'random',
    questionCount: number,
    targetScorePercent: number,
    isPro: boolean,
    isProEligible: boolean,
    questionManipulation: PredefinedPromptKey | undefined
  ) => Promise<string | null>;
  deletePracticeTest: (practiceTestId: string) => Promise<boolean>;
  startPracticeTest: (practiceTestId: string) => void;
  saveAnswerSelection: (
    questionId: string,
    selectedAnswer: string | null
  ) => Promise<void>;
  selectQuestion: (questionId: string) => void;
  loadPracticeTestQuestion: (questionId: string) => void;
  saveHint: (questionId: string, hint: string) => void;
  saveDistractors: (questionId: string, distractors: string[]) => void;
  saveExplanation: (questionId: string, explanation: string) => void;
  removeQuestion: (questionId: string) => void;
  submitPracticeTest: () => Promise<void>;
  savePracticeTestSummary: (practiceTestId: string, summary: string) => void;
  selectedPracticeTest: PracticeTestMetadata | null;
  selectedPracticeTestData: PracticeTestData | null;
  selectedPracticeTestIdData: string | null;
  selectedPracticeTestQuestionId: string | null;
  selectedPracticeTestQuestion: PracticeTestQuestion | null;
  isCreatingPracticeTest: boolean;
  isLoadingPracticeTest: boolean;
  isLoadingPracticeTestQuestion: boolean;
  practiceTests: { [id: string]: PracticeTestMetadata };
}

export const PracticeTestContext = createContext<IPracticeTestContext>(null);

export const PracticeTestProvider = (props) => {
  const [practiceTests, setPracticeTests] = useState<{
    [id: string]: PracticeTestMetadata;
  }>({});

  const [practiceTestsLoaded, setPracticeTestsLoaded] = useState(false);

  const [selectedPracticeTestId, setSelectedPracticeTestId] = useState<
    string | null
  >(null);
  const [selectedPracticeTestIdData, setSelectedPracticeTestIdData] = useState<
    string | null
  >(null);
  const [selectedPracticeTestData, setSelectedPracticeTestData] =
    useState<PracticeTestData | null>(null);
  const [selectedPracticeTestQuestionId, setSelectedPracticeTestQuestionId] =
    useState<string | null>(null);
  const [isLoadingPracticeTestQuestion, setIsLoadingPracticeTestQuestion] =
    useState(false);
  const [isCreatingPracticeTest, setIsCreatingPracticeTest] = useState(false);
  const [isLoadingPracticeTest, setIsLoadingPracticeTest] = useState(false);

  const [surveys, setSurveys] = useState<Surveys>({});

  const { user, token, isProEligible } = useAuth();
  const { decks } = useDeck();
  const router = useRouter();

  // On Practice Tests Loaded - Check for deck in route and prepare page as needed
  useEffect(() => {
    if (practiceTestsLoaded && !router.pathname.includes('practice/new')) {
      const practiceTestId = getPracticeTestIdFromRoute();

      if (practiceTestId && !practiceTests[practiceTestId]) {
        // send the user back home if we don't have this deck in metadata anymore
        router.replace('/practice');
      } else if (practiceTestId) {
        setSelectedPracticeTestId(practiceTestId);
      } else {
        setIsLoadingPracticeTest(false);
      }
    }
  }, [practiceTestsLoaded]);

  useEffect(() => {
    if (token) {
      const unsubscribe = PracticeTestDB.subscribeToPracticeTests(
        (practiceTests) => {
          setPracticeTests(practiceTests);
          setPracticeTestsLoaded(true);
        }
      );
      return () => unsubscribe();
    }
  }, [token]);

  useEffect(() => {
    if (
      selectedPracticeTestData &&
      selectedPracticeTestData.questions.length > 0 &&
      !practiceTests[selectedPracticeTestId].isSubmitted &&
      !selectedPracticeTestData.questions.some(
        (t) => t.id === selectedPracticeTestQuestionId
      )
    ) {
      const firstUnansweredQuestion =
        selectedPracticeTestData.questions.find((t) => !t.selectedAnswer) ||
        selectedPracticeTestData.questions[
          selectedPracticeTestData.questions.length - 1
        ];

      setSelectedPracticeTestQuestionId(firstUnansweredQuestion.id);
    }
  }, [selectedPracticeTestData, selectedPracticeTestQuestionId]);

  useEffect(() => {
    if (selectedPracticeTestId) {
      setIsLoadingPracticeTest(true);
      setSelectedPracticeTestQuestionId(null);

      const unsubscribe = PracticeTestDB.subscribeToData(
        selectedPracticeTestId,
        (practiceTestData) => {
          setSelectedPracticeTestData(practiceTestData);
          setIsLoadingPracticeTest(false);
          setSelectedPracticeTestIdData(selectedPracticeTestId);
        }
      );
      return () => unsubscribe();
    }
  }, [selectedPracticeTestId]);

  useEffect(() => {
    setSelectedPracticeTestId(null);
    const practiceTestId = getPracticeTestIdFromRoute();

    if (practiceTestsLoaded && practiceTests[practiceTestId]) {
      setSelectedPracticeTestId(practiceTestId);
    }
  }, [router.query]);

  useEffect(() => {
    if (selectedPracticeTestData && selectedPracticeTestQuestionId) {
      // TODO: Fix the order context so we can check IsPro too for using gpt4
      // Currently we are assuming that if they are pro eligible that will cover all pro subs
      loadPracticeTestQuestion(selectedPracticeTestQuestionId, isProEligible);
    }
  }, [selectedPracticeTestQuestionId]);

  const createPracticeTest = async (
    name: string,
    deckIds: string[],
    questionDistribution: 'even' | 'weighted' | 'random',
    questionCount: number,
    targetScorePercent: number,
    isPro: boolean,
    isProEligible: boolean,
    questionManipulation: PredefinedPromptKey | undefined
  ): Promise<string | null> => {
    let newPracticeTestId = null;

    try {
      setIsCreatingPracticeTest(true);
      const newPracticeTest = {
        id: uuidv4(),
        name,
        status: `In Progress - 0/${questionCount} Questions Answered`,
        deckIds: deckIds,
        questionCount,
        questionsCorrect: 0,
        isSubmitted: false,
        passed: false,
        targetScorePercent,
        questionDistribution
      } as PracticeTestMetadata;

      if (questionManipulation) {
        newPracticeTest.questionManipulation = questionManipulation;
      }

      const allDataPromises: Promise<DeckData>[] = [];

      deckIds.forEach((deckId) => {
        allDataPromises.push(DecksDB.getData(deckId));
      });

      const allData = await Promise.all(allDataPromises);

      let availableQuestions: PracticeTestQuestion[] = [];
      const deckCounts: { [deckId: string]: number } = {};

      deckIds.forEach((deckId, index) => {
        const deckData = allData[index];

        deckCounts[deckId] = 0;

        availableQuestions.push(
          ...deckData.cards.map((card) => {
            return {
              id: uuidv4(),
              deckId,
              question: card.question,
              answer: card.answer,
              distractors: [],
              selectedAnswer: null
            } as PracticeTestQuestion;
          })
        );
      });

      let usedQuestions: PracticeTestQuestion[] = [];

      availableQuestions = shuffle(availableQuestions);

      while (usedQuestions.length < questionCount) {
        if (deckIds.length > 1) {
          // TODO !!
          if (questionDistribution === 'even') {
            // find the deck with the least amount of questions and pull a card from that deck
            let arr = Object.values(deckCounts);
            let min = Math.min(...arr);

            const [minDeckId] = Object.entries(deckCounts).find(
              ([deckId, value]) => value === min
            );

            const newQuestion = availableQuestions.find(
              (question) => question.deckId === minDeckId
            );

            availableQuestions = availableQuestions.filter(
              (q) => q.id !== newQuestion.id
            );

            deckCounts[minDeckId] += 1;

            if (!availableQuestions.some((a) => a.deckId === minDeckId)) {
              delete deckCounts[minDeckId];
            }

            usedQuestions.push(newQuestion);
          } else if (questionDistribution === 'weighted') {
            // find the target distribution for each deck and grab a question from the deck that is underneath the target distribution

            usedQuestions.push(availableQuestions.pop());

            // TODO
            // let arr = Object.values(deckCounts);
            // let min = Math.min(...arr);

            // const [minDeckId] = Object.entries(deckCounts).find(
            //   ([deckId, value]) => value === min
            // );

            // const newQuestion = availableQuestions.find(
            //   (question) => question.deckId === minDeckId
            // );

            // availableQuestions = availableQuestions.filter(
            //   (q) => q.id !== newQuestion.id
            // );

            // deckCounts[minDeckId] += 1;

            // usedQuestions.push(newQuestion);
          } else {
            usedQuestions.push(availableQuestions.pop());
          }
        } else {
          usedQuestions.push(availableQuestions.pop());
        }
      }

      const practiceTestData = {
        version: '1',
        questions: shuffle(usedQuestions)
      } as PracticeTestData;

      await PracticeTestDB.updateData(newPracticeTest.id, practiceTestData);
      await PracticeTestDB.create(newPracticeTest);

      submitFeedback(
        `New Practice Test (${questionCount.toString()}): ${user?.email} ${
          isPro ? 'pro' : 'free'
        } ${
          isProEligible && !isPro
            ? ' eligible'
            : !isProEligible
            ? ' not eligible'
            : ''
        } ${questionManipulation ? '(' + questionManipulation + ')' : ''}`
      );

      newPracticeTestId = newPracticeTest.id;
    } catch (e) {
      submitFeedback(`Something went wrong making practice test: ${e}`);
      console.error(e);
    } finally {
      setIsCreatingPracticeTest(false);

      return newPracticeTestId;
    }
  };

  const selectQuestion = (questionId: string) => {
    setSelectedPracticeTestQuestionId(questionId);
  };

  const deletePracticeTest = async (practiceTestId: string) => {
    const practiceTest = practiceTests[practiceTestId];

    const result = window.confirm(
      `Are you sure you want to delete Practice Test ${practiceTest.name} and all ${practiceTest.questionCount} questions?`
    );

    if (result) {
      practiceTest.isDeleted = true;
      await PracticeTestDB.updatePracticeTest(practiceTestId, practiceTest);
    }

    return result;
  };

  const saveAnswerSelection = async (
    questionId: string,
    selectedAnswer: string | null
  ) => {
    const updatedPracticeTestData = { ...selectedPracticeTestData };

    const question = updatedPracticeTestData.questions.find(
      (question) => question.id === questionId
    );

    if (question.selectedAnswer === selectedAnswer) {
      return;
    }

    question.selectedAnswer = selectedAnswer;

    const questionsAnswered = updatedPracticeTestData.questions.filter(
      (question) => question.selectedAnswer
    ).length;

    const questionsCorrect = updatedPracticeTestData.questions.filter(
      (question) => question.selectedAnswer === question.answer
    ).length;

    const selectedPracticeTest = practiceTests[selectedPracticeTestId];

    selectedPracticeTest.status = `In Progress - ${questionsAnswered}/${selectedPracticeTest.questionCount} Questions Answered`;
    selectedPracticeTest.questionsCorrect = questionsCorrect;

    setSelectedPracticeTestData(updatedPracticeTestData);

    await PracticeTestDB.updateData(
      selectedPracticeTestId,
      updatedPracticeTestData
    );
    await PracticeTestDB.updatePracticeTest(
      selectedPracticeTestId,
      selectedPracticeTest
    );
  };

  const removeQuestion = async (questionId: string) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      const updatedPracticeTestData = { ...selectedPracticeTestData };

      const newQuestions = updatedPracticeTestData.questions.filter(
        (q) => q.id !== questionId
      );

      updatedPracticeTestData.questions = newQuestions;

      const questionsAnswered = updatedPracticeTestData.questions.filter(
        (question) => question.selectedAnswer
      ).length;

      const questionsCorrect = updatedPracticeTestData.questions.filter(
        (question) => question.selectedAnswer === question.answer
      ).length;

      const selectedPracticeTest = practiceTests[selectedPracticeTestId];

      selectedPracticeTest.status = `In Progress - ${questionsAnswered}/${selectedPracticeTest.questionCount} Questions Answered`;
      selectedPracticeTest.questionsCorrect = questionsCorrect;
      selectedPracticeTest.questionCount =
        updatedPracticeTestData.questions.length;

      setSelectedPracticeTestData(updatedPracticeTestData);

      await PracticeTestDB.updateData(
        selectedPracticeTestId,
        updatedPracticeTestData
      );
      await PracticeTestDB.updatePracticeTest(
        selectedPracticeTestId,
        selectedPracticeTest
      );
    }
  };

  const fetchDistractors = async (
    question: PracticeTestQuestion,
    practiceTest: PracticeTestMetadata,
    useGpt4: boolean
  ) => {
    let generatedChoices = [];

    if (practiceTest.questionManipulation) {
      try {
        const input = `{ "question": "${question.question}", "answer": "${question.answer}" }`;
        const output = await GenericResponseAPI.getPredefinedResponse(
          practiceTest.questionManipulation,
          input
        );

        if (output.question) {
          question.originalQuestion = { ...question };
          question.question = output.question;
          if (practiceTest.questionManipulation === 'increaseDifficulty') {
            question.answer = output.answer;
          }
        }
      } catch (e) {
        submitFeedback('Failed to manipuate question: ' + e.toString());
        console.log('Failed to manipuate question: ' + e.toString());
      }
    }

    try {
      // First, let's try to get the enhanced question if the source is Anki (remove fomatting, cloze, etc)
      if (question.source === 'anki') {
        const originalQuestion = { ...question };

        try {
          const enhancedQuestion = await DistractorsAPI.getEnhancedQuestion(
            question.question,
            question.answer
          );

          question.question = enhancedQuestion.question;
          question.answer = enhancedQuestion.answer;
        } catch (e) {
          console.error(e);
          submitFeedback('Failed to enhance question');
        }

        if (!question.question.endsWith('?')) {
          submitFeedback(
            'Bad Anki Question: ___ original: ' +
              JSON.stringify(originalQuestion) +
              ' ___ output: ' +
              JSON.stringify(question)
          );
        }
      }

      const selectedDeck = decks[question.deckId];

      const promptLanguage =
        selectedDeck &&
        selectedDeck.language?.name &&
        selectedDeck.language?.name.toLowerCase() !== 'english'
          ? selectedDeck.language?.name
          : undefined;

      generatedChoices = await DistractorsAPI.getEnhancedDistractors(
        question.question,
        question.answer,
        useGpt4,
        promptLanguage
      );
    } catch (e) {
      if (user?.uid) {
        submitFeedback('PT Enhanced Distractors Failed: ' + JSON.stringify(e));
      }

      try {
        generatedChoices = await DistractorsAPI.getSimpleDistractors(
          question.answer
        );
      } catch (e) {
        if (user?.uid) {
          submitFeedback('PT Simple Distractors Failed: ' + e.toString());
        }
      }
    }

    return generatedChoices;
  };

  const loadPracticeTestQuestion = async (
    questionId: string,
    useGpt4: boolean,
    preloading = false
  ) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      if (!preloading) {
        setIsLoadingPracticeTestQuestion(true);
      }

      const selectedPracticeTest = practiceTests[selectedPracticeTestId];

      const question = selectedPracticeTestData.questions.find(
        (q) => q.id === questionId
      );

      const questionIndex = selectedPracticeTestData.questions.findIndex(
        (q) => q.id === questionId
      );

      const nextQuestion =
        questionIndex + 1 < selectedPracticeTestData.questions.length
          ? selectedPracticeTestData.questions[questionIndex + 1]
          : null;

      if (question.distractors.length === 0) {
        const generatedDistractors = await fetchDistractors(
          question,
          selectedPracticeTest,
          useGpt4
        );

        if (generatedDistractors.length > 0) {
          question.distractors = generatedDistractors;
        } else {
          window.alert(
            'Failed to load this question. Try refreshing this question or coming back to it later. This issue will be investigated.  This question can also be removed the test by using the triple dots in the corner.'
          );
        }

        await PracticeTestDB.updateData(
          selectedPracticeTestId,
          selectedPracticeTestData
        );
      }

      if (nextQuestion && !preloading) {
        loadPracticeTestQuestion(nextQuestion.id, useGpt4, true);
      }

      if (!preloading) {
        setIsLoadingPracticeTestQuestion(false);
      }
    }
  };

  const saveDistractors = async (questionId: string, distractors: string[]) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      const question = selectedPracticeTestData.questions.find(
        (q) => q.id === questionId
      );

      question.distractors = distractors;

      await PracticeTestDB.updateData(
        selectedPracticeTestId,
        selectedPracticeTestData
      );
    }
  };

  const saveHint = async (questionId: string, hint: string) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      const question = selectedPracticeTestData.questions.find(
        (q) => q.id === questionId
      );

      question.hint = hint;

      await PracticeTestDB.updateData(
        selectedPracticeTestId,
        selectedPracticeTestData
      );
    }
  };

  const saveSummary = async (questionId: string, hint: string) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      const question = selectedPracticeTestData.questions.find(
        (q) => q.id === questionId
      );

      question.hint = hint;

      await PracticeTestDB.updateData(
        selectedPracticeTestId,
        selectedPracticeTestData
      );
    }
  };

  const saveExplanation = async (questionId: string, explanation: string) => {
    if (selectedPracticeTestId && selectedPracticeTestData) {
      const question = selectedPracticeTestData.questions.find(
        (q) => q.id === questionId
      );

      question.explanation = explanation;

      await PracticeTestDB.updateData(
        selectedPracticeTestId,
        selectedPracticeTestData
      );
    }
  };

  const savePracticeTestSummary = async (
    practiceTestId: string,
    summary: string
  ) => {
    try {
      if (selectedPracticeTestId === practiceTestId) {
        const selectedPracticeTest = practiceTests[selectedPracticeTestId];

        selectedPracticeTest.summary = summary;
        selectedPracticeTest.generatedSummary = true;

        setPracticeTests({ ...practiceTests });

        await PracticeTestDB.updatePracticeTest(
          selectedPracticeTestId,
          selectedPracticeTest
        );
      }
    } catch (e) {}
  };

  const submitPracticeTest = async () => {
    try {
      if (selectedPracticeTestId) {
        const selectedPracticeTest = practiceTests[selectedPracticeTestId];

        if (
          !selectedPracticeTestData.questions.every((t) => t.selectedAnswer)
        ) {
          window.alert(
            'Cannot submit yet. Please select an answer for every question.'
          );
        } else {
          const scorePercent = Math.round(
            (selectedPracticeTest.questionsCorrect /
              selectedPracticeTest.questionCount) *
              100
          );

          selectedPracticeTest.isSubmitted = true;

          if (scorePercent >= selectedPracticeTest.targetScorePercent) {
            selectedPracticeTest.status = `Passed - ${scorePercent}%`;
            selectedPracticeTest.passed = true;
          } else {
            selectedPracticeTest.status = `Failed - ${scorePercent}%`;
            selectedPracticeTest.passed = false;
          }

          setSelectedPracticeTestQuestionId(null);

          await PracticeTestDB.updatePracticeTest(
            selectedPracticeTestId,
            selectedPracticeTest
          );

          submitFeedback('Practice Test Submitted by ' + user?.email);
        }
      }
    } catch (e) {
      submitFeedback('Practice Test Submission Failed: ' + e);
    }
  };

  const submitFeedback = async (message: string) => {
    await FeedbackDB.create(message);
  };

  const value = {
    practiceTests,
    selectedPracticeTest: selectedPracticeTestId
      ? practiceTests[selectedPracticeTestId]
      : null,
    selectedPracticeTestData,
    selectedPracticeTestQuestionId,
    selectedPracticeTestQuestion:
      selectedPracticeTestData && selectedPracticeTestQuestionId
        ? selectedPracticeTestData.questions.find(
            (t) => t.id === selectedPracticeTestQuestionId
          )
        : null,
    createPracticeTest,
    deletePracticeTest,
    saveAnswerSelection,
    selectQuestion,
    saveDistractors,
    savePracticeTestSummary,
    saveHint,
    saveExplanation,
    loadPracticeTestQuestion,
    removeQuestion,
    submitPracticeTest,
    isLoadingPracticeTestQuestion,
    isCreatingPracticeTest,
    selectedPracticeTestIdData,
    isLoadingPracticeTest
  };

  return <PracticeTestContext.Provider value={value} {...props} />;
};
