import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { Stepper } from '@/components/memorize/common/stepper/Stepper';
import { State } from 'fsrs.js';
import React from 'react';

export default function Help({ onClose }) {
  const { trackEvent, analyticsInstance } = useAnalytics();

  return (
    <>
      <div className="w-full text-center text-lg font-bold">
        What is spaced repetition?
      </div>
      <div className="mt-2 w-full text-center">
        Spaced repetition is a method of reviewing flashcards at systematic
        intervals. Instead of studying all flashcards all the time, a{' '}
        <a
          onClick={() => {
            trackEvent(analyticsInstance, 'memorize_help_click_algorithm');
          }}
          className="link"
          target="_blank"
          href="https://github.com/open-spaced-repetition/fsrs4anki/wiki/The-Algorithm"
        >
          special algorithm
        </a>{' '}
        is used to show only the flashcards you need to study.
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        How does it work?
      </div>
      <div className="mt-2 w-full text-center">
        Every flashcard is assigned one of three states: New, Learn, and Review.
        Every time you study a flashcard you will rate it as Again, Hard, Good
        or Easy. These ratings will determine the new state of the flashcard and
        when it needs to be studied again. Flashcards that have not yet been
        studied are always placed in the New state. Flashcards that are not
        fully memorized or have been forgotten are placed in Learn state.
        Flashcards you have memorized and will be reviewed for retention are
        placed in review state.
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        What does Limbiks do?
      </div>
      <div className="mt-2 w-full text-center">
        Limbiks will automatically keep track of the state of your flashcards
        and when they are due to be studied. Using the tracker, you will be able
        to see for any given deck how many flashcards are in New, Learn and
        Review state. Note that the tracker will only show the totals for cards
        that are due today.
      </div>
      <div className="mt-4 flex w-full justify-center">
        <Stepper new={'New'} learn={'Learn'} review={'Review'} />
      </div>
      <div className="mt-4 w-full text-center">
        When studying, the tracker will highlight orange the state of the
        current flashcard.
      </div>
      <div className="mt-4 flex w-full justify-center">
        <Stepper highlightState={State.New} new={5} learn={5} review={5} />
      </div>
      <div className="mt-4 w-full text-center">
        Once all flashcards in a given state have been completed you will see a
        checkbox in the tracker.
      </div>
      <div className="mt-4 flex w-full justify-center">
        <Stepper new={0} learn={10} review={5} />
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        How do I stop memorizing or start over?
      </div>
      <div className="mt-2 w-full text-center">
        By right clicking on a deck from the main page, or clicking the triple
        dots in the top right corner while memorizing a deck, you can select
        "Stop Memorizing" which will remove the deck from the memorize page. If
        you want to start memorizing that deck again, simply add the deck again
        and your study history will be preserved. If you want to start over with
        a deck you can select "Reset Schedule". This will erase all study
        history and put all flashcards back into new state. This is not usually
        recommended since the algorithm is designed to keep track of your
        flashcards over long periods of time.
      </div>
      <div className="mt-6 w-full text-center text-lg font-bold">
        Give Feedback!
      </div>
      <div className="mt-2 w-full text-center">
        This is a brand new feature and we will be making improvements and
        adding more configurability in the future. Please provide any feedback
        you may have for how we can better help you memorize your flashcards!
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
      </div>
    </>
  );
}
