/**
 * Take the difference between the dates and divide by milliseconds per day.
 * Round to nearest whole number to deal with DST.
 */
function datediff(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

// TODO Move these date utils somewhere else
declare global {
  interface Date {
    isLeapYear: (year) => boolean;
    getDaysInMonth: () => number;
    addMonths: (months: number) => Date;
  }
}

/**
 * new Date("dateString") is browser-dependent and discouraged, so we'll write
 * a simple parse function for U.S. date format (which does no error checking)
 */
function parseDate(str) {
  var mdy = str.split('/');
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

const isLeapYear = function (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const getDaysInMonth = function (year, month) {
  return [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31
  ][month];
};

Date.prototype.isLeapYear = function () {
  return isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
  return getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};

export const getDaysFromNow = (date: Date) => {
  return datediff(new Date(), date);
};

export const getResetDate = (startDate: Date) => {
  const resetDates = generateResetDates(startDate);
  const soonestDate = resetDates.find((date) => date > new Date());
  return soonestDate;
};

export const generateResetDates = (startDate: Date) => {
  const resetDates = [] as Date[];

  console.log(startDate);
  console.log(startDate.getTime());
  var testDate = new Date(startDate.getTime());
  console.log(testDate);
  console.log(testDate.addMonths(1));

  // go into the future 10 years (120 months)
  for (let i = 0; i <= 120; i++) {
    var copiedDate = new Date(startDate.getTime());
    resetDates.push(copiedDate.addMonths(i));
  }

  return resetDates;
};
