import React, { useState } from 'react';
import { useSettings } from '@/app/context/SettingsContext/useSettings';

export function RenameFolder({ onClose, selectedFolderId }) {
  const { renameFolder, folders } = useSettings();

  const [folderName, setFolderName] = useState('');

  const selectedFolderName = folders.find(
    (t) => t.id === selectedFolderId
  )?.name;

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && folderName?.length > 0) {
      await renameFolder(selectedFolderId, folderName);
      onClose();
    }
  };

  return (
    <div className="h-full">
      <div className="mb-4 w-full text-lg font-bold">Rename folder</div>
      <div className="text-black">
        <input
          autoFocus
          value={folderName}
          onKeyDown={handleKeyDown}
          onChange={(e) => setFolderName(e.currentTarget.value)}
          placeholder={selectedFolderName}
          className="focus:shadow-outline input input-bordered w-full appearance-none rounded border bg-white px-3 py-2 leading-tight text-black shadow focus:outline-none"
        />
      </div>
      <div className="mt-4 w-full text-xs">
        Enter a new name for the folder <b>{selectedFolderName}</b>.
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
        <div>
          <button
            onClick={async () => {
              await renameFolder(selectedFolderId, folderName);
              onClose();
            }}
            className={`btn ${folderName ? 'btn-primary' : 'btn-disabled'}`}
          >
            Rename
          </button>
        </div>
      </div>
    </div>
  );
}
