import { useDeck } from '@/app/context/DeckContext/useDeck';
import React from 'react';
import Loader from '../../loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { CardData } from '@/app/types/database/DeckData';
import {
  combineBoundingBoxes,
  doBoundingBoxesOverlap,
  isBoundingBoxBeforeOtherBoundingBox
} from '@/app/utils/ocrUtils';
import { OcclusionData } from '@/app/types/Generator/OcclusionData';
import { insertAt } from '@/app/utils/arrayUtils';

interface IProps {
  onClose: () => void;
  selectedFilename: string;
}

export function EditOcclusion({ onClose, selectedFilename }: IProps) {
  const {
    selectedDeckData,
    selectedDeck,
    updateSelectedDeckData,
    media,
    occlusions,
    refreshMediaIfExpiring
  } = useDeck();

  const [updatedCards, setUpdatedCards] = React.useState<CardData[]>([]);
  const [updatedOcclusions, setUpdatedOcclusions] = React.useState<
    OcclusionData[]
  >([]);

  const [isSaving, setIsSaving] = React.useState(false);

  const [occlusionToCombine, setOcclusionToCombine] =
    React.useState<OcclusionData | null>(null);

  const [canvasEventListener, setCanvasEventListener] = useState(null);
  const [spacePressed, setSpacePressed] = useState(false);

  const [cardToCombine, setCardToCombine] = React.useState<CardData | null>(
    null
  );

  const [baseImageWidth, setBaseImageWidth] = useState(0);
  const [baseImageHeight, setBaseImageHeight] = useState(0);

  const clickHandlerRef = useRef(null);

  useEffect(() => {
    refreshMediaIfExpiring();
  }, []);

  const canvasRef = useRef(null);
  const backgroundCanvasRef = useRef(null);

  const [id] = useState(uuidv4());

  const onSave = async () => {
    try {
      setIsSaving(true);

      const firstCardIndex = selectedDeckData.cards.findIndex(
        (c) => c.question === selectedFilename
      );

      const existingCards = selectedDeckData.cards.filter(
        (c) => c.question !== selectedFilename
      );

      const finalCards = insertAt(
        existingCards,
        firstCardIndex,
        ...updatedCards
      );

      const newSelectedDeckData = {
        ...selectedDeckData,
        cards: finalCards
      };

      await updateSelectedDeckData(newSelectedDeckData);
      onClose();
    } catch {
      window.alert('Failed to save changes');
      setIsSaving(false);
    }
  };

  const onCombineCard = (card: CardData) => {
    if (!cardToCombine && !occlusionToCombine) {
      setCardToCombine(card);
    } else if (occlusionToCombine) {
      const isCardFirst = isBoundingBoxBeforeOtherBoundingBox(
        JSON.parse(card.answer),
        occlusionToCombine.boundingBox
      );

      const combinedText = isCardFirst
        ? JSON.parse(card.answer).text + ' ' + occlusionToCombine.text
        : occlusionToCombine.text + ' ' + JSON.parse(card.answer).text;

      const newCard = {
        ...card,
        id: uuidv4(),
        // TODO: figure out which block is on top / to the left (which block comes first)
        text: combinedText,
        answer: JSON.stringify({
          text: combinedText,
          ...combineBoundingBoxes(
            occlusionToCombine.boundingBox,
            JSON.parse(card.answer)
          )
        })
      };

      const newCards = updatedCards.filter((c) => c.id !== card.id);

      newCards.push(newCard);

      setUpdatedCards(newCards);
      setCardToCombine(newCard);
      setOcclusionToCombine(null);
    } else if (card !== cardToCombine) {
      const isCardFirst = isBoundingBoxBeforeOtherBoundingBox(
        JSON.parse(card.answer),
        JSON.parse(cardToCombine.answer)
      );

      const combinedText = isCardFirst
        ? JSON.parse(card.answer).text +
          ' ' +
          JSON.parse(cardToCombine.answer).text
        : JSON.parse(cardToCombine.answer).text +
          ' ' +
          JSON.parse(card.answer).text;

      const newCard = {
        ...card,
        id: uuidv4(),
        // Todo figure out which block is on top / to the left (which block comes first)
        text: combinedText,
        answer: JSON.stringify({
          text: combinedText,
          ...combineBoundingBoxes(
            JSON.parse(card.answer),
            JSON.parse(cardToCombine.answer)
          )
        })
      };

      const newCards = updatedCards.filter(
        (c) => c.id !== cardToCombine.id && c.id !== card.id
      );

      newCards.push(newCard);

      setUpdatedCards(newCards);
      setCardToCombine(newCard);
    }
  };

  const onCombineOcclusion = (occlusion: OcclusionData) => {
    if (!occlusionToCombine && !cardToCombine) {
      setOcclusionToCombine(occlusion);
    } else if (cardToCombine) {
      const isOcclusionFirst = isBoundingBoxBeforeOtherBoundingBox(
        occlusion.boundingBox,
        JSON.parse(cardToCombine.answer)
      );

      const combinedText = isOcclusionFirst
        ? occlusion.text + ' ' + JSON.parse(cardToCombine.answer).text
        : JSON.parse(cardToCombine.answer).text + ' ' + occlusion.text;

      const newCard = {
        ...cardToCombine,
        id: uuidv4(),
        // Todo figure out which block is on top / to the left (which block comes first)
        text: combinedText,
        answer: JSON.stringify({
          text: combinedText,
          ...combineBoundingBoxes(
            occlusion.boundingBox,
            JSON.parse(cardToCombine.answer)
          )
        })
      };

      const newCards = updatedCards.filter((c) => c.id !== cardToCombine.id);

      newCards.push(newCard);

      setUpdatedCards(newCards);
      setCardToCombine(newCard);
      setOcclusionToCombine(null);
    } else if (occlusion !== occlusionToCombine) {
      // TODO V2: If there are any occlusions underneath what is being combined, we should auto combine those too

      const isOcclusionFirst = isBoundingBoxBeforeOtherBoundingBox(
        occlusion.boundingBox,
        occlusionToCombine.boundingBox
      );

      const combinedText = isOcclusionFirst
        ? occlusion.text + ' ' + occlusionToCombine.text
        : occlusionToCombine.text + ' ' + occlusion.text;

      const combinedOcclusion = {
        ...occlusion,
        // Todo figure out which block is on top / to the left (which block comes first)
        text: combinedText,
        words: [...occlusionToCombine.words, ...occlusion.words],
        boundingBox: combineBoundingBoxes(
          occlusion.boundingBox,
          occlusionToCombine.boundingBox
        )
      } as OcclusionData;

      const newOcclusions = updatedOcclusions.filter(
        (o) => o !== occlusionToCombine && o !== occlusion
      );

      newOcclusions.push(combinedOcclusion);

      setUpdatedOcclusions(newOcclusions);
      setOcclusionToCombine(combinedOcclusion);
    }
  };

  const addOcclusion = (occlusion: OcclusionData) => {
    const newCard: CardData = {
      engine: 'v1',
      id: uuidv4(),
      dataId: selectedDeck.id,
      importance: 1,
      index: occlusion.index,
      name: 'Page ' + occlusion.index,
      text: occlusion.text,
      question: occlusion.filename,
      answer: JSON.stringify({
        text: occlusion.text,
        left: occlusion.boundingBox.left,
        top: occlusion.boundingBox.top,
        width: occlusion.boundingBox.width,
        height: occlusion.boundingBox.height
      })
    };

    setUpdatedCards([...updatedCards, newCard]);
  };

  const splitOcclusion = (occlusion: OcclusionData) => {
    if (occlusion.words.length > 0) {
      const newOcclusions = occlusion.words.map(
        (word) =>
          ({
            filename: occlusion.filename,
            index: occlusion.index,
            text: word.text,
            boundingBox: word.boundingBox,
            words: [word]
          } as OcclusionData)
      );

      const newUpdatedOcclusions = [
        ...updatedOcclusions.filter((o) => o !== occlusion),
        ...newOcclusions
      ];

      setUpdatedOcclusions(newUpdatedOcclusions);
    }
  };

  const getImageDimensions = () => {
    if (baseImageHeight > 0 && baseImageWidth > 0) {
      return { width: baseImageWidth, height: baseImageHeight };
    }

    const image = media.find((t) => t.filename === selectedFilename);

    var modalContainer = document.getElementsByClassName('modal-box');

    var modalWidth = modalContainer[0].clientWidth;
    var modalHeight = modalContainer[0].clientHeight;

    const availableWidth = modalWidth - 50;
    const availableHeight = modalHeight - 75;

    let imageHeight = availableHeight;
    let imageWidth =
      ((image.bbox[2] - image.bbox[0]) / (image.bbox[3] - image.bbox[1])) *
      imageHeight;

    if (imageWidth > availableWidth) {
      imageWidth = availableWidth;
      imageHeight =
        ((image.bbox[3] - image.bbox[1]) / (image.bbox[2] - image.bbox[0])) *
        imageWidth;
    }

    setBaseImageWidth(imageWidth);
    setBaseImageHeight(imageHeight);

    return { width: imageWidth, height: imageHeight };
  };

  const initialize = () => {
    const existingCards = selectedDeckData.cards.filter(
      (c) => c.question === selectedFilename
    );

    setUpdatedCards(existingCards);
    setUpdatedOcclusions(occlusions);
    setOcclusionToCombine(null);
    setCardToCombine(null);
  };

  useEffect(() => {
    if (selectedFilename && selectedDeckData) {
      initialize();
    }
  }, [selectedFilename, selectedDeckData, occlusions]);

  useEffect(() => {
    // make sure ctrl click doesn't do anything while editing
    const prevent = (event) => event.preventDefault();

    document.addEventListener('contextmenu', prevent);

    const remove = () => {
      document.removeEventListener('contextmenu', prevent);
    };

    return remove;
  }, []);

  useEffect(() => {
    if (!backgroundCanvasRef.current) {
      return null;
    }

    const image = media.find((t) => t.filename === selectedFilename);

    if (!image) {
      return null;
    }

    const drawImage = () => {
      var canvas = backgroundCanvasRef.current;
      var context = canvas.getContext('2d');

      // var modalContainer = document.getElementsByClassName('modal-box');

      // var modalWidth = modalContainer[0].clientWidth;
      // var modalHeight = modalContainer[0].clientHeight;

      // const availableWidth = modalWidth - 50;
      // const availableHeight = modalHeight - 75;

      // console.log(modalWidth);
      // console.log(modalHeight);

      // let imageHeight = availableHeight;
      // let imageWidth =
      //   ((image.bbox[2] - image.bbox[0]) / (image.bbox[3] - image.bbox[1])) *
      //   imageHeight;

      // if (imageWidth > availableWidth) {
      //   imageWidth = availableWidth;
      //   imageHeight =
      //     ((image.bbox[3] - image.bbox[1]) / (image.bbox[2] - image.bbox[0])) *
      //     imageWidth;
      // }

      const imageDimenstions = getImageDimensions();
      const imageWidth = imageDimenstions.width;
      const imageHeight = imageDimenstions.height;

      canvas.width = imageWidth;
      canvas.height = imageHeight;

      canvas.width = imageWidth;
      canvas.height = imageHeight;

      var img = new Image();
      img.onload = function () {
        context.drawImage(img, 0, 0, imageWidth, imageHeight);
      };

      img.src = image.signedUrl;
    };

    return drawImage();
  }, [media]);

  useEffect(() => {
    if (canvasRef.current) {
      var canvas = canvasRef.current;

      const callback = (e) => {
        if (clickHandlerRef.current) {
          clickHandlerRef.current(e);
        }
      };

      canvas.addEventListener('mousedown', callback);

      return () => canvas.removeEventListener('mousedown', callback);
    }
  }, [canvasRef]);

  useEffect(() => {
    const onSpaceDown = (event: KeyboardEvent) => {
      if (event.key == 'Space' || event.key === ' ') {
        event.preventDefault();
        setSpacePressed(true);
      }
    };

    const onSpaceUp = (event: KeyboardEvent) => {
      if (event.key == 'Space' || event.key === ' ') {
        event.preventDefault();
        setSpacePressed(false);
      }
    };

    window.addEventListener('keydown', onSpaceDown);

    window.addEventListener('keyup', onSpaceUp);

    return () => {
      window.removeEventListener('keydown', onSpaceDown);
      window.removeEventListener('keyup', onSpaceUp);
    };
  }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return null;
    }

    const image = media.find((t) => t.filename === selectedFilename);

    if (!image) {
      return null;
    }

    const allOcclusions = updatedOcclusions.filter(
      (o) => o.filename === selectedFilename
    );

    // Find occlusions that don't overlap with any existing flashcards
    const availableOcclusions = allOcclusions.filter(
      (o) =>
        !updatedCards.some((c) =>
          doBoundingBoxesOverlap(JSON.parse(c.answer), o.boundingBox)
        )
    );

    const drawOcclusions = () => {
      var canvas = canvasRef.current;
      var context = canvas.getContext('2d');

      // var modalContainer = document.getElementsByClassName('modal-box');

      // var modalWidth = modalContainer[0].clientWidth;
      // var modalHeight = modalContainer[0].clientHeight;

      // const availableWidth = modalWidth - 50;
      // const availableHeight = modalHeight - 75;

      // let imageHeight = availableHeight;
      // let imageWidth =
      //   ((image.bbox[2] - image.bbox[0]) / (image.bbox[3] - image.bbox[1])) *
      //   imageHeight;

      // if (imageWidth > availableWidth) {
      //   imageWidth = availableWidth;
      //   imageHeight =
      //     ((image.bbox[3] - image.bbox[1]) / (image.bbox[2] - image.bbox[0])) *
      //     imageWidth;
      // }

      const imageDimenstions = getImageDimensions();
      const imageWidth = imageDimenstions.width;
      const imageHeight = imageDimenstions.height;

      canvas.width = imageWidth;
      canvas.height = imageHeight;

      context.clearRect(0, 0, canvas.width, canvas.height);

      type BoundingBoxItem = {
        boundingBox: any;
        itemType: string;
        item: any;
      };

      const boundingBoxItems: BoundingBoxItem[] = [];

      boundingBoxItems.push(
        ...updatedCards.map(
          (c) =>
            ({
              boundingBox: JSON.parse(c.answer),
              itemType: 'card',
              item: c
            } as BoundingBoxItem)
        )
      );

      boundingBoxItems.push(
        ...availableOcclusions.map(
          (o) =>
            ({
              boundingBox: o.boundingBox,
              itemType: 'occlusion',
              item: o
            } as BoundingBoxItem)
        )
      );

      const clickHandler = (event) => {
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left) / canvas.width;
        const y = (event.clientY - rect.top) / canvas.height;

        // Check if the click is inside any of the bounding boxes
        for (let i = 0; i < boundingBoxItems.length; i++) {
          const box = boundingBoxItems[i].boundingBox;

          if (
            x >= box.left &&
            x <= box.left + box.width &&
            y >= box.top &&
            y <= box.top + box.height
          ) {
            const item = boundingBoxItems[i];

            if (item.itemType === 'card') {
              const card = item.item;
              if (event?.shiftKey) {
                onCombineCard(card);
              } else {
                setUpdatedCards(updatedCards.filter((c) => c.id !== card.id));
                setCardToCombine(null);
                setOcclusionToCombine(null);
              }
            } else {
              const availableOcclusion = item.item;

              if (event?.ctrlKey) {
                splitOcclusion(availableOcclusion);
                setCardToCombine(null);
                setOcclusionToCombine(null);
              } else if (event?.shiftKey) {
                onCombineOcclusion(availableOcclusion);
              } else {
                addOcclusion(availableOcclusion);
                setCardToCombine(null);
                setOcclusionToCombine(null);
              }
            }
          }
        }
      };

      clickHandlerRef.current = clickHandler;

      const drawRectangle = (boundingBox, color, strokeColor = '') => {
        context.beginPath();
        context.rect(
          boundingBox.left * imageWidth,
          boundingBox.top * imageHeight,
          boundingBox.width * imageWidth,
          boundingBox.height * imageHeight
        );
        context.fillStyle = color;
        context.fill();

        if (strokeColor) {
          context.strokeStyle = strokeColor;
          context.lineWidth = 3;
          context.strokeRect(
            boundingBox.left * imageWidth,
            boundingBox.top * imageHeight,
            boundingBox.width * imageWidth,
            boundingBox.height * imageHeight
          );
        }
      };

      for (const card of updatedCards) {
        const boundingBox = JSON.parse(card.answer);

        drawRectangle(
          boundingBox,
          spacePressed ? 'rgba(255, 99, 71, 1)' : 'rgba(255, 99, 71, .5)',
          !spacePressed && cardToCombine && cardToCombine.id === card.id
            ? 'rgba(202, 138, 4, .5)'
            : ''
        );
      }

      if (!spacePressed) {
        for (const availableOcclusion of availableOcclusions) {
          drawRectangle(
            availableOcclusion.boundingBox,
            'rgba(55, 182, 121, .4)',
            !spacePressed &&
              occlusionToCombine &&
              occlusionToCombine === availableOcclusion
              ? 'rgba(202, 138, 4, .5)'
              : ''
          );
        }
      }

      return () => {
        canvas.removeEventListener('click', clickHandler, false);
      };
    };

    return drawOcclusions();
  }, [
    updatedCards,
    updatedOcclusions,
    cardToCombine,
    occlusionToCombine,
    spacePressed
  ]);

  return (
    <div>
      {isSaving && (
        <div className="mt-8 flex justify-center">
          <Loader />
        </div>
      )}
      <div className={isSaving ? 'invisible' : 'visible'}>
        <div className="mb-8 flex flex-col items-center gap-4 md:flex-row">
          <div className="flex-1">
            <b>{updatedCards.length} Occlusions</b>{' '}
            <span className=" text-gray-500">
              {'('}scroll down to see instructions for editing occlusions{')'}
            </span>
          </div>
          <div className="flex gap-4">
            <button className="btn-default btn btn-sm" onClick={onClose}>
              Close
            </button>
            <button className="btn btn-warning btn-sm" onClick={initialize}>
              Reset
            </button>
            <button className="btn btn-primary btn-sm" onClick={onSave}>
              Save Changes
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-1">
            <canvas
              className="col-start-1 row-start-1"
              width={800}
              height={800}
              ref={backgroundCanvasRef}
            />
            <canvas
              className="col-start-1 row-start-1"
              width={800}
              height={800}
              ref={canvasRef}
            />
          </div>
        </div>

        <div className="mt-4 font-bold">Instructions</div>
        <div>
          To <b className="text-green">add</b> an occlusion, click on a{' '}
          <span className="bg-green p-1 text-white">green box</span>
        </div>
        <div>
          To <b className="text-primary">remove</b> an existing occlusion, click
          on an <span className="bg-primary p-1 text-white">orange box</span>
        </div>
        <div>
          To <b className="text-yellow-600">combine</b> occlusions, hold shift
          and click on the boxes you want to combine.
        </div>
        <div>
          To <b className="text-blue">break up</b> a green box into individual
          words, hold control and click on the box you want to break up.
        </div>
        <div>
          To <b className="text-purple">preview</b> your occlusions, hold the
          space bar.
        </div>
      </div>
    </div>
  );
}
