export const Generator = {
  Question: 'Question',
  Cloze: 'Cloze',
  ImageOcclusion: 'ImageOcclusion'
};

export type GeneratorOptions = {
  generator: keyof typeof Generator;
  skipParsing: boolean;
  customPages: number[];
  includeSlideContent?: boolean;
  includeNotesSection?: boolean;
  maxFlashcards: number;
  generatorBehavior: string;
  hasLearningObjectives?: boolean;
  hasCustomInstructions?: boolean;
  occludeAllText?: boolean;
};
