import { DeckStatus } from '@/app/constants/DeckStatus';
import firebaseApp from '../firebase-app';
import fsrs from 'fsrs.js';
import { v4 as uuidv4 } from 'uuid';
import {
  doc,
  getFirestore,
  onSnapshot,
  runTransaction,
  deleteField,
  setDoc,
  DocumentData,
  getDoc,
  collection,
  endAt,
  orderBy,
  startAt,
  query,
  getDocs,
  writeBatch
} from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getUtcNowTimestamp } from '@/app/utils/timeUtils';
import { DeckMetadata } from '@/app/types/database/DeckMetadata';
import { CardData, DeckData } from '@/app/types/database/DeckData';
import { getCardBack, getCardFront } from '@/app/utils/flashcardUtils';
import { CardSchedule } from '@/app/types/database/CardSchedule';

const create = async (
  name: string,
  fileType: string,
  videoId?: string,
  folderId?: string
): Promise<string> => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const allowedFileTypes = ['pptx', 'docx', 'pdf', 'txt', 'png', 'jpg', 'jpeg'];

  if (!allowedFileTypes.includes(fileType)) {
    throw 'Invalid File Type';
  }

  // If someone is using the preview feature, sign them in anonymously...
  if (!auth.currentUser) {
    await signInAnonymously(auth);
  }

  const newDeckId = uuidv4();

  const deckMetadata = {
    isAnonymous: auth.currentUser.isAnonymous,
    name: name,
    fileType,
    status: DeckStatus.PendingUpload,
    cardCount: 0,
    pageCount: 0,
    generatorRuns: 0,
    pageImages: [],
    pageImagesReady: false,
    created: getUtcNowTimestamp()
  } as DeckMetadata;

  if (videoId) {
    deckMetadata.videoId = videoId;
  }

  if (folderId) {
    deckMetadata.folderId = folderId;
  }

  await setDoc(
    doc(db, 'decks', auth.currentUser.uid),
    {
      [newDeckId]: deckMetadata
    },
    { merge: true }
  );

  return newDeckId;
};

const updateStatus = async (deckId: string, status: DeckStatus) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'decks', auth.currentUser.uid),
    {
      [deckId]: { status }
    },
    { merge: true }
  );
};

const updateMetadata = async (
  deckId: string,
  metadata: Partial<DeckMetadata>
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'decks', auth.currentUser.uid),
    {
      [deckId]: metadata
    },
    { merge: true }
  );
};

const updateCardSchedules = async (
  deckId: string,
  cardSchedules: CardSchedule[]
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'decks', auth.currentUser.uid, 'cardSchedules', deckId),
    {
      cardSchedules
    }
  );
};

const updateData = async (deckId: string, data: DeckData) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  await runTransaction(db, async (transaction) => {
    // Update Metadata
    transaction.set(
      doc(db, 'decks', auth.currentUser.uid),
      {
        [deckId]: { cardCount: data.cards.length }
      },
      { merge: true }
    );

    // Group cards by dataId (cards are stored in batches of 500)
    const batches = data.cards.reduce((acc, card) => {
      const dataId = card.dataId;
      if (dataId) {
        if (!acc[dataId]) {
          acc[dataId] = [];
        }
        acc[dataId].push(card);
      }
      return acc;
    }, {});

    // Update each batch separately
    for (const dataId in batches) {
      const batchData = {
        ...data,
        cards: batches[dataId]
      };

      const newDataId = dataId.startsWith(deckId)
        ? dataId
        : deckId + '_' + dataId;

      transaction.set(
        doc(db, 'decks', auth.currentUser.uid, 'data', newDataId),
        batchData,
        { merge: false }
      );
    }
  });
};

const remove = async (id: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Get all documents that start with the id prefix
  const querySnapshot = await getDocs(
    collection(db, 'decks', auth.currentUser.uid, 'data')
  );
  const batch = writeBatch(db);

  querySnapshot.forEach((queryDoc) => {
    if (queryDoc.id.startsWith(id)) {
      // Delete Cards
      batch.delete(doc(db, 'decks', auth.currentUser.uid, 'data', queryDoc.id));
    }
  });

  // Delete Metadata
  batch.set(
    doc(db, 'decks', auth.currentUser.uid),
    {
      [id]: deleteField()
    },
    { merge: true }
  );

  await batch.commit();
};

// Deck cards are stored as a subcollection
const subscribeToDecks = (
  onChange: (deckMap: { [id: string]: DeckMetadata }) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(doc(db, 'decks', auth.currentUser.uid), (doc) => {
    const decks = (doc.data() as { [id: string]: DeckMetadata }) || {};

    const validDecks = {};

    Object.keys(decks).forEach((deckId) => {
      const deck = decks[deckId];
      if (
        deck &&
        typeof deck.created === 'object' &&
        typeof deck.cardCount === 'number' &&
        typeof deck.name === 'string' &&
        typeof deck.status === 'string'
      ) {
        validDecks[deckId] = deck;
      }
    });

    onChange(validDecks);
  });

  return unsub;
};

const prepDeckData = (dataId: string, deckData: DocumentData) => {
  if (!deckData) {
    return null;
  }

  if (deckData.cards.length > 0 && typeof deckData.cards[0] === 'string') {
    const v2Cards = deckData.cards.map(
      (t) =>
        ({
          question: getCardFront(t),
          answer: getCardBack(t),
          text: ''
        } as CardData)
    );

    deckData.cards = v2Cards;
  }

  if (
    deckData.cards.length > 0 &&
    typeof deckData.cards[0].metadata !== 'undefined'
  ) {
    const v2Cards = deckData.cards.map((t) => {
      const index =
        parseInt(t.metadata?.index || '0') > 0
          ? parseInt(t.metadata?.index || '0')
          : 0;

      const cardData = {
        id: t.id,
        question: t.question,
        answer: t.answer,
        text: t.text || '',
        index: index,
        name: 'Page ' + index
      } as CardData;

      return cardData;
    });

    deckData.cards = v2Cards;
  }

  for (const card of deckData.cards) {
    if (!card.id) {
      card.id = uuidv4();
    }

    card.dataId = dataId;
  }
};

const getData = async (deckId: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Query documents starting with deckId
  const dataQuery = query(
    collection(db, 'decks', auth.currentUser.uid, 'data'),
    orderBy('__name__'),
    startAt(deckId),
    endAt(deckId + '\uf8ff')
  );

  const querySnapshot = await getDocs(dataQuery);

  let allCards = [];
  let deckData: DeckData = { version: '2', cards: [] };

  querySnapshot.forEach((doc) => {
    deckData = doc.data() as DeckData;
    prepDeckData(doc.id, deckData);

    allCards = [...allCards, ...deckData.cards];
  });

  return { ...deckData, cards: allCards } as DeckData;
};

const getCardSchedules = async (deckId: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Query documents starting with deckId
  const dataQuery = query(
    collection(db, 'decks', auth.currentUser.uid, 'cardSchedules')
  );

  const querySnapshot = await getDocs(dataQuery);

  let cardSchedules: any[] = [];

  querySnapshot.forEach((doc) => {
    cardSchedules = [...cardSchedules, ...(doc.data().cardSchedules as any[])];
  });

  return cardSchedules as CardSchedule[];
};

const subscribeToData = (
  deckId: string,
  onChange: (data: DeckData) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Query documents starting with deckId
  const dataQuery = query(
    collection(db, 'decks', auth.currentUser.uid, 'data'),
    orderBy('__name__'),
    startAt(deckId),
    endAt(deckId + '\uf8ff')
  );

  const unsub = onSnapshot(dataQuery, (querySnapshot) => {
    let allCards = [];
    let deckData: DeckData = { version: '2', cards: [] };

    querySnapshot.forEach((doc) => {
      deckData = doc.data() as DeckData;
      prepDeckData(doc.id, deckData);

      allCards = [...allCards, ...deckData.cards];
    });
    onChange({ ...deckData, cards: allCards } || null);
  });

  return unsub;
};

const subscribeToCardSchedules = (
  onChange: (cardSchedules: CardSchedule[]) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  // Query documents starting with deckId
  const dataQuery = query(
    collection(db, 'decks', auth.currentUser.uid, 'cardSchedules')
  );

  const getDueDate = (cardSchedule: CardSchedule) => {
    return new Date((cardSchedule.due as any).seconds * 1000);
  };

  const getLastReviewDate = (cardSchedule: CardSchedule) => {
    return new Date((cardSchedule.last_review as any).seconds * 1000);
  };

  const unsub = onSnapshot(dataQuery, (querySnapshot) => {
    let cardSchedules: CardSchedule[] = [];

    querySnapshot.forEach((doc) => {
      cardSchedules = [
        ...cardSchedules,
        ...(doc.data().cardSchedules as CardSchedule[])
      ];
    });

    const fixedCardSchedules = cardSchedules.map((cs) => ({
      ...cs,
      due: getDueDate(cs),
      last_review: getLastReviewDate(cs)
    }));

    onChange(fixedCardSchedules);
  });

  return unsub;
};

export default {
  create,
  remove,
  updateStatus,
  updateData,
  getData,
  getCardSchedules,
  subscribeToDecks,
  subscribeToData,
  subscribeToCardSchedules,
  updateMetadata,
  updateCardSchedules
};
