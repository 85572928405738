export function getCardFront(text: string) {
  // remove line breaks
  text = text.replace(/(\r\n|\n|\r)/gm, ' ');
  return text.replace(/\{{(.*?)\}}/g, '______');
}

export function getCardBack(text: string) {
  // remove line breaks
  text = text.replace(/(\r\n|\n|\r)/gm, ' ');
  const matches = text.match(/\{{(.*?)\}}/g);

  if (!matches) {
    return '';
  }

  let result = '';

  for (const match of matches) {
    if (result.length > 0) {
      result += ', ' + match.slice(2, -2);
    } else {
      result += match.slice(2, -2);
    }
  }

  return result;
}

export function getHighlightedText(text: string) {
  const expression = `{{2,}([^]*)}{2,}`;
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(expression, 'gi'));
  const found = text.match(expression) || [];
  return (
    <p>
      {parts.map((part) =>
        found.includes(part) ? (
          <span className="text-secondary">{part}</span>
        ) : (
          <span>{part}</span>
        )
      )}
    </p>
  );
}
