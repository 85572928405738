export type CountryInfo = {
  countryCode: string;
  currencyCode: string;
  currencySymbol: string;
  currencySuffix: string;
  monthlyRate: number;
  yearlyRate: number;
  monthlyPriceId: string;
  yearlyPriceId: string;
};

export const supportedCountries: Record<string, CountryInfo> = {
  AU: {
    countryCode: 'AU',
    currencyCode: 'AUD',
    currencySymbol: '$',
    currencySuffix: ' AUD',
    monthlyRate: 8,
    yearlyRate: 65,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_AUD_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_AUD_YEARLY_PRICE_ID
  },
  CA: {
    countryCode: 'CA',
    currencyCode: 'CAD',
    currencySymbol: '$',
    currencySuffix: ' CAD',
    monthlyRate: 7,
    yearlyRate: 59,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_CAD_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_CAD_YEARLY_PRICE_ID
  },
  NZ: {
    countryCode: 'NZ',
    currencyCode: 'NZD',
    currencySymbol: '$',
    currencySuffix: ' NZD',
    monthlyRate: 9,
    yearlyRate: 70,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_NZD_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_NZD_YEARLY_PRICE_ID
  },
  PH: {
    countryCode: 'PH',
    currencyCode: 'PHP',
    currencySymbol: '₱',
    currencySuffix: '',
    monthlyRate: 250,
    yearlyRate: 2000,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_PHP_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_PHP_YEARLY_PRICE_ID
  },
  US: {
    countryCode: 'US',
    currencyCode: 'USD',
    currencySymbol: '$',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_YEARLY_PRICE_ID
  },
  GD: {
    countryCode: 'GD',
    currencyCode: 'XCD',
    currencySymbol: '$',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_YEARLY_PRICE_ID
  },
  VC: {
    countryCode: 'VC',
    currencyCode: 'XCD',
    currencySymbol: '$',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_YEARLY_PRICE_ID
  },
  BB: {
    countryCode: 'BB',
    currencyCode: 'BBD',
    currencySymbol: '$',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_YEARLY_PRICE_ID
  },
  GB: {
    countryCode: 'GB',
    currencyCode: 'GBP',
    currencySymbol: '£',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_GBP_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_GBP_YEARLY_PRICE_ID
  },
  // UK isn't an official ISO 3166-1 Code, but it is reserved by UK so put it here just in case
  UK: {
    countryCode: 'UK',
    currencyCode: 'GBP',
    currencySymbol: '£',
    currencySuffix: '',
    monthlyRate: 5,
    yearlyRate: 40,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_GBP_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_GBP_YEARLY_PRICE_ID
  },
  ZA: {
    countryCode: 'ZA',
    currencyCode: 'ZAR',
    currencySymbol: 'R',
    currencySuffix: '',
    monthlyRate: 100,
    yearlyRate: 800,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_ZAR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_ZAR_YEARLY_PRICE_ID
  },
  // European Union Countries
  BE: {
    countryCode: 'BE',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  BG: {
    countryCode: 'BG',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  CZ: {
    countryCode: 'CZ',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  DK: {
    countryCode: 'DK',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  DE: {
    countryCode: 'DE',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  EE: {
    countryCode: 'EE',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  IE: {
    countryCode: 'IE',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  EL: {
    countryCode: 'EL',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  GR: {
    countryCode: 'GR',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  ES: {
    countryCode: 'ES',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  FR: {
    countryCode: 'FR',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  HR: {
    countryCode: 'HR',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  IT: {
    countryCode: 'IT',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  CY: {
    countryCode: 'CY',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  LV: {
    countryCode: 'LV',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  LT: {
    countryCode: 'LT',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  LU: {
    countryCode: 'LU',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  HU: {
    countryCode: 'HU',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  MT: {
    countryCode: 'MT',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  NL: {
    countryCode: 'NL',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  AT: {
    countryCode: 'AT',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  PL: {
    countryCode: 'PL',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  PT: {
    countryCode: 'PT',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  RO: {
    countryCode: 'RO',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  SI: {
    countryCode: 'SI',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  SK: {
    countryCode: 'SK',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  FI: {
    countryCode: 'FI',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  },
  SE: {
    countryCode: 'SE',
    currencyCode: 'EUR',
    currencySymbol: '€',
    currencySuffix: '',
    monthlyRate: 6,
    yearlyRate: 47,
    monthlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_EUR_YEARLY_PRICE_ID
  }
};
