import FeedbackDB from '@/app/database/feedback/FeedbackDB';
import SurveysDB from '@/app/database/surveys/SurveysDB';
import React, { createContext, useEffect, useState } from 'react';
import { useAuth } from '../AuthContext/useAuth';
import { Surveys } from '@/app/types/database/Surveys';
import { GeneratorActivity } from '@/app/types/database/GeneratorActivity';
import TrackingAPI from '@/app/api/TrackingAPI/TrackingAPI';
import { DeckActivity } from '@/app/types/database/DeckActivity';

interface IFeedbackContext {
  submitFeedback: (text: string) => Promise<void>;
  submitSurvey1: (feature: string) => void;
  surveys: Surveys;
  trackDeckActivity: (activity: DeckActivity) => void;
  trackGeneratorActivity: (activity: GeneratorActivity) => void;
}

export const FeedbackContext = createContext<IFeedbackContext>(null);

export const FeedbackProvider = (props) => {
  const [surveys, setSurveys] = useState<Surveys>({});

  const { user, token } = useAuth();

  useEffect(() => {
    if (token) {
      const unsubscribe = SurveysDB.subscribeToSurveys((surveys) => {
        setSurveys(surveys);
      });

      return () => unsubscribe();
    }
  }, [token]);

  const trackDeckActivity = async (activity: DeckActivity) => {
    try {
      await TrackingAPI.trackDeckActivity(activity);
    } catch {
      // FeedbackDB.create(
      //   `Track Deck Failed: ${user.uid} \n\n ${JSON.stringify(activity)}`
      // );
    }
  };

  const trackGeneratorActivity = async (activity: GeneratorActivity) => {
    try {
      await TrackingAPI.trackGeneratorActivity(activity);
    } catch {
      FeedbackDB.create(
        `Track Generator Failed: ${user.uid} \n\n ${JSON.stringify(activity)}`
      );
    }
  };

  const submitFeedback = async (message: string) => {
    try {
      await FeedbackDB.create(message);
    } catch {}
  };

  const submitSurvey1 = async (feature: string) => {
    await SurveysDB.updateSurvey1(feature);
  };

  const value = {
    submitFeedback,
    submitSurvey1,
    trackGeneratorActivity,
    trackDeckActivity,
    surveys
  };

  return <FeedbackContext.Provider value={value} {...props} />;
};
