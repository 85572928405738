import { useAuth } from '../context/AuthContext/useAuth';

// declare var fbq: (
//   track: string,
//   event: string,
//   params: any,
//   eventId?: any
// ) => void;

export default function useFacebookTracking() {
  const { user } = useAuth();

  const trackSignUp = () => {
    // try {
    //   if (fbq) {
    //     fbq('track', 'StartTrial', {});
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
  };

  const trackCheckoutSesion = (value: number) => {
    // try {
    //   if (fbq) {
    //     fbq(
    //       'track',
    //       'InitiateCheckout',
    //       { currency: 'USD', value },
    //       { eventID: user.uid } // supply subscription id as event id so we don't track purchases twice
    //     );
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
  };

  const trackPurchase = (value: number) => {
    // try {
    //   if (fbq) {
    //     fbq(
    //       'track',
    //       'Purchase',
    //       { currency: 'USD', value },
    //       { eventID: user.uid } // supply subscription id as event id so we don't track purchases twice
    //     );
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
  };

  const trackPreview = () => {
    // try {
    //   if (fbq) {
    //     fbq('track', 'ViewContent', { content_name: 'Preview' });
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
  };

  return { trackSignUp, trackCheckoutSesion, trackPurchase, trackPreview };
}
