import React, { useState } from 'react';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { useFeedback } from '@/app/context/FeedbackContext/useFeedback';

export function CreateFolder({ onClose }) {
  const { createFolder } = useSettings();

  const [folderName, setFolderName] = useState('');

  const { submitFeedback } = useFeedback();

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && folderName?.length > 0) {
      await createFolder(folderName);
      submitFeedback('New Folder Created (Enter Key) - ' + folderName);
      onClose();
    }
  };

  return (
    <div className="h-full">
      <div className="mb-4 w-full text-lg font-bold">Create folder</div>
      <div className="text-black">
        <input
          autoFocus
          onKeyDown={handleKeyDown}
          value={folderName}
          onChange={(e) => setFolderName(e.currentTarget.value)}
          placeholder="Biology"
          className="focus:shadow-outline input input-bordered w-full appearance-none rounded border bg-white px-3 py-2 leading-tight text-black shadow focus:outline-none"
        />
      </div>
      <div className="mt-4 w-full text-xs">
        Create a new folder to organize your decks. When selected, new uploads
        will be placed in this folder automatically. Existing decks can be moved
        into this folder by right clicking (desktop) or long pressing (mobile)
        on any deck.
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
        <div>
          <button
            onClick={async () => {
              await createFolder(folderName);
              submitFeedback('New Folder Created - ' + folderName);
              onClose();
            }}
            className={`btn ${folderName ? 'btn-primary' : 'btn-disabled'}`}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}
