import ErrorAPI from '@/app/api/ErrorAPI/ErrorAPI';
import React from 'react';
import { v4 } from 'uuid';

interface IProps {
  children: JSX.Element;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  private correlationId = v4();

  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    try {
      const errorMessage =
        this.correlationId +
        '______' +
        JSON.stringify(error) +
        '______' +
        JSON.stringify(errorInfo);

      ErrorAPI.postClientError(errorMessage);
    } finally {
      // You can use your own error logging service here
      console.error({ error, errorInfo });
    }
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="p-4">
          <h2>Sorry! Something went wrong.</h2>
          <div className="mt-3">
            If the issue persists, please email support@limbiks.com with the
            following information:
          </div>
          <div className="m-8 flex flex-col gap-8">
            <div>1. Your Limbiks Account Email Address</div>
            <div>
              2. Brief description or screenshot of what you were doing before
              this error occurred
            </div>
            <div>
              3. This Correlation ID <b>{this.correlationId}</b> to help support
              diagnose the issue.
            </div>
            <div>
              Sorry for the inconvenience, and we appreciate your patience.
              Thanks!
            </div>
          </div>
          <button
            className="btn"
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
