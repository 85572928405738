import firebaseApp from '@/app/database/firebase-app';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const deleteAccount = async () => {
  const auth = getAuth(firebaseApp);

  await axios.delete(`/api/account/delete`, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

const getToken = async (uid: string): Promise<string> => {
  const auth = getAuth(firebaseApp);

  return (
    await axios.get(`/api/account/token`, {
      params: { uid },
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    })
  ).data;
};

export default {
  deleteAccount,
  getToken
};
