import { useContext } from 'react';
import { SettingsContext } from './SettingsProvider';

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      `useSettings must be used within a SettingsContextProvider.`
    );
  }
  return context;
};
