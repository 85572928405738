import firebaseApp from '@/app/database/firebase-app';
import { MultipleChoiceQuestion } from '@/app/types/database/MultipleChoiceQuestion';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

type CheckoutSessionDetails = {
  currency: string;
  total: number;
};

const getCheckoutSessionDetails = async (
  sessionId: string
): Promise<CheckoutSessionDetails> => {
  const auth = getAuth(firebaseApp);

  const response = await axios.get<CheckoutSessionDetails>(
    `/api/checkoutSessionDetails?sessionId=${sessionId}`,
    {
      headers: { token: await auth.currentUser.getIdToken() },
      timeout: 0
    }
  );

  return response.data;
};

export default {
  getCheckoutSessionDetails
};
