import { Feedback } from '@/app/types/database/Feedback';
import firebaseApp from '../firebase-app';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getUtcNowTimestamp } from '@/app/utils/timeUtils';

const create = async (message: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  if (auth.currentUser?.uid) {
    const feedback: Feedback = {
      userId: auth.currentUser.uid,
      message,
      created: getUtcNowTimestamp()
    };

    await addDoc(collection(db, 'feedback'), feedback);
  }
};

export default {
  create
};
