import React, { useState } from 'react';
import Select from 'react-select';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { useDeck } from '@/app/context/DeckContext/useDeck';

export function ChangeFolder({ onClose, selectedDeckId }) {
  const { decks, changeFolder } = useDeck();

  const [newFolder, setNewFolder] = useState<any>(null);

  const selectedDeck = decks[selectedDeckId];

  const { folders } = useSettings();

  const folderOptions = folders
    // != instead of !== because undefined is equal to null
    .filter((folder) => folder.id != selectedDeck.folderId)
    .map((folder) => ({
      value: folder.id,
      label: folder.name
    }));

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'left',
      color: 'black'
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontSize: 14,
      color: 'black'
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
  };

  return (
    <div className="h-full">
      <div className="mb-4 w-full text-lg font-bold">Change folder</div>
      <div className="text-black">
        <Select
          name="folder"
          options={folderOptions}
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={setNewFolder}
          value={newFolder}
          menuPortalTarget={document.body}
        ></Select>
      </div>
      <div className="mt-4 w-full text-xs">
        Choose a new folder for the deck <b>{selectedDeck.name}</b>.
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
        <div>
          <button
            onClick={async () => {
              await changeFolder(selectedDeckId, newFolder?.value);
              onClose();
            }}
            className={`btn ${newFolder ? 'btn-primary' : 'btn-disabled'}`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
