import firebaseApp from '@/app/database/firebase-app';
import { DeckActivity } from '@/app/types/database/DeckActivity';
import { DeletedCard } from '@/app/types/database/DeletedCard';
import { GeneratorActivity } from '@/app/types/database/GeneratorActivity';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const trackDeletedCard = async (deletedCard: DeletedCard) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(`/api/track/deletedCard`, deletedCard, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

const trackDeckActivity = async (activity: DeckActivity) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(`/api/track/deck`, activity, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

const trackGeneratorActivity = async (activity: GeneratorActivity) => {
  const auth = getAuth(firebaseApp);

  await axios.post<string>(`/api/track/generator`, activity, {
    headers: { token: await auth.currentUser.getIdToken() },
    timeout: 0
  });
};

export default {
  trackDeckActivity,
  trackGeneratorActivity,
  trackDeletedCard
};
