import React from 'react';

interface IProps {
  text: string;
  disabled: boolean;
}

export default function SubmitButton(props: IProps) {
  const activeClassName =
    'mt-8 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-base-content shadow-sm transition duration-300 ease-in-out hover:bg-base-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600';
  const disabledClassName =
    'mt-8 flex w-full justify-center rounded-md opacity-30 bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-base-content shadow-sm';

  return (
    <button
      disabled={props.disabled}
      type="submit"
      className={props.disabled ? disabledClassName : activeClassName}
    >
      {props.text}
    </button>
  );
}
