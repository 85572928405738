import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import myAccountImage from 'public/my-account-screenshot.png';
import Image from 'next/image';
import Link from 'next/link';

export const proFeatures = {
  uploads: '100 uploads / month',
  pagesAndSlides: '200 pages or slides / upload',
  studyGuides: 'AI Generated Study Guides',
  multipleChoice: 'Unlimited multiple choice questions',
  practiceTests: 'Unlimited Practice Tests',
  practiceTestQuestions: '100 questions / practice test',
  hintsAndExplanations: 'Hints and Explanations',
  customInstructions: 'Generate with custom instructions',
  learningObjectives: 'Add learning objectives',
  more: 'and more...'
};

export const defaultFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'studyGuides',
  'multipleChoice',
  'hintsAndExplanations'
];

export const practiceTestFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'customInstructions',
  'learningObjectives',
  'practiceTests',
  'studyGuides',
  'multipleChoice'
];

export const uploadFeatures: Array<keyof typeof proFeatures> = [
  'uploads',
  'pagesAndSlides',
  'practiceTests',
  'practiceTestQuestions',
  'studyGuides'
];

interface IProps {
  onClose: () => void;
}

export default function UpdgradeSuccessModal(props: IProps) {
  return (
    <div
      key={'123'}
      className="flex flex-col justify-between rounded-3xl bg-white/5 text-black backdrop-blur-2xl"
    >
      <h3 id={'123'} className="text-center text-xl font-semibold text-black">
        Welcome to Limbiks Pro!
      </h3>
      <div className="mt-8 text-center font-bold text-gray-600">
        You now have more uploads, more pages and slides per upload, and full
        access to every Limbiks feature.
      </div>
      <div className="mt-4 text-center text-gray-600">
        Go to the{' '}
        <Link href="/app/account">
          <span onClick={props.onClose} className="text-primary underline">
            My Account
          </span>
        </Link>{' '}
        page to view your monthly usage, account information, and managae your
        subscription.
      </div>

      <Image
        className="mt-8"
        alt="my account"
        src={myAccountImage}
        width={8000}
      ></Image>
      <div className="mt-4 w-full">
        <button
          onClick={props.onClose}
          className="btn btn-sm float-right w-20 bg-gray-500"
        >
          Close
        </button>
      </div>
    </div>
  );
}
