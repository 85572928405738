import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import SignInForm from './sign-in-form/SignInForm';
import CloseIcon from '@/icons/CloseIcon';
import CreateAccountForm from './create-account-form/CreateAccountForm';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import Image from 'next/image';
import booksImage from 'public/books.jpg';

export default function AuthModal() {
  const { showAuth, hideAuth, isAuthShowing, isSignUpShowing, redirectUrl } =
    useAuth();

  const onClose = () => {
    hideAuth();
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    content: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000000,
      overflowY: 'auto'
    }
  };

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isAuthShowing}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div
        onClick={onClose}
        className="fixed right-1 top-1 h-8 w-8 hover:cursor-pointer"
      >
        <CloseIcon fill="rgb(140,140,140)" />
      </div>
      <div className="flex min-h-full min-w-full">
        <div className="hidden flex-1 lg:block">
          <Image
            alt="books"
            className="min-h-full min-w-full object-cover"
            src={booksImage}
          />
        </div>

        {isSignUpShowing ? (
          <CreateAccountForm onSignIn={() => showAuth(false, redirectUrl)} />
        ) : (
          <SignInForm
            onClose={onClose}
            onCreateAccount={() => showAuth(true, redirectUrl)}
          />
        )}
      </div>
    </Modal>
  );
}
