import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import Select from 'react-select';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import { dynamicSort } from '@/app/utils/arrayUtils';

export function MergeDecks({ onClose, selectedDeckId }) {
  const router = useRouter();

  const { decks, mergeDecks } = useDeck();

  const [selectedDeckValues, setSelectedDeckValues] = useState<any>([]);

  const selectedDeck = decks[selectedDeckId];

  const { foldersEnabled, toggleFoldersEnabled } = useSettings();

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'left'
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontSize: 14
    })
  };

  const sortedDecks = Object.entries(decks)
    .map(([deckId, metadata]) => ({
      deckId,
      ...metadata,
      inverseCreated: metadata.created.toMillis() * -1
    }))
    .filter((d) => d.deckId !== selectedDeckId && d.cardCount > 0)
    .sort(dynamicSort('inverseCreated'))
    .map((deck) => ({
      value: deck.deckId,
      label: `${deck.name} (${deck.cardCount})`
    }));

  return (
    <div className="h-full">
      <div className="mb-4 w-full text-lg font-bold">Merge Decks</div>
      <div className="text-black">
        <Select
          isMulti
          name="decks"
          options={sortedDecks}
          styles={customStyles}
          classNamePrefix="react-select"
          onChange={setSelectedDeckValues}
          value={selectedDeckValues}
        ></Select>
      </div>
      <div className="mt-4 w-full text-xs">
        Select one or more decks you would like to merge into the deck{' '}
        <b>{selectedDeck.name}</b>.
      </div>
      <div className="mt-2 w-full text-xs">
        All flashcards from the selected decks will be copied into this deck.
        The selected decks will not be modified. You can delete them after
        merging if you would like.
      </div>
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
        <div>
          <button
            onClick={async () => {
              await mergeDecks([
                selectedDeckId,
                ...selectedDeckValues.map((t) => t.value)
              ]);
              onClose();
            }}
            className={`btn ${
              selectedDeckValues.length > 0 ? 'btn-primary' : 'btn-disabled'
            }`}
          >
            Merge
          </button>
        </div>
      </div>
    </div>
  );
}
