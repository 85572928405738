import firebaseApp from '../firebase-app';
import {
  collection,
  addDoc,
  getFirestore,
  onSnapshot
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { StripeSubscription } from '@/app/types/database/StripeSubscription';
import { CountryInfo } from '@/app/constants/SupportedCountries';

const getCustomerPortalUrl = async () => {
  const functions = getFunctions(firebaseApp, 'us-east1');

  const functionRef = httpsCallable(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  );

  const { data } = await functionRef({
    returnUrl: window.location.origin + '/app/account?from=customer_portal'
  });

  return (data as any).url as string;
};

const createAndSubscribeToCheckoutSession = async (
  isMonthly: boolean,
  country: CountryInfo,
  onChange: (url: string, error: string) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const docRef = await addDoc(
    collection(db, 'customers', auth.currentUser.uid, 'checkout_sessions'),
    {
      price: isMonthly ? country.monthlyPriceId : country.yearlyPriceId,
      success_url:
        window.location.origin +
        window.location.pathname +
        `?action=purchase&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url:
        window.location.origin +
        window.location.pathname +
        `?action=cancel&session_id={CHECKOUT_SESSION_ID}`
    }
  );

  const unsub = onSnapshot(docRef, (doc) => {
    const { error, url } = doc.data();
    if (error) {
      onChange(null, `An error occured: ${error.message}`);
    }
    if (url) {
      onChange(url, null);
    }
  });

  return unsub;
};

const subscribeToNonActiveStripeSubscription = (
  onChange: (subscription: StripeSubscription | null) => void
) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  var ref = collection(db, 'customers', auth.currentUser.uid, 'subscriptions');

  const unsub = onSnapshot(ref, (doc) => {
    const stripeSubscriptions: StripeSubscription[] = [];

    for (const subscription of doc.docs) {
      stripeSubscriptions.push(subscription.data() as StripeSubscription);
    }

    const activeStripeSusbcription = stripeSubscriptions.find(
      (sub) => sub.status === 'active'
    );

    if (activeStripeSusbcription) {
      onChange(null);
    } else {
      const nonActiveStripeSusbcription = stripeSubscriptions.find(
        (sub) => sub.status !== 'active'
      );

      onChange(nonActiveStripeSusbcription);
    }
  });

  return unsub;
};

const subscribeToActiveStripeSubscription = (
  onChange: (subscription: StripeSubscription | null) => void,
  submitFeedback: (message: string) => void
) => {
  try {
    const db = getFirestore(firebaseApp);
    const auth = getAuth(firebaseApp);

    var ref = collection(
      db,
      'customers',
      auth.currentUser.uid,
      'subscriptions'
    );

    const unsub = onSnapshot(ref, (doc) => {
      try {
        const stripeSubscriptions: StripeSubscription[] = [];

        for (const subscription of doc.docs) {
          stripeSubscriptions.push(subscription.data() as StripeSubscription);
        }

        const activeStripeSusbcription = stripeSubscriptions.find(
          (sub) => sub.status === 'active' || sub.status === 'trialing'
        );

        onChange(activeStripeSusbcription || null);
      } catch (e) {
        submitFeedback('Customer Error: ' + JSON.stringify(e));
      }
    });

    return unsub;
  } catch (e) {
    submitFeedback('Customer Outer Error: ' + JSON.stringify(e));
  }
};

export default {
  createAndSubscribeToCheckoutSession,
  getCustomerPortalUrl,
  subscribeToActiveStripeSubscription,
  subscribeToNonActiveStripeSubscription
};
