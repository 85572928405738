export function getPaddedNumberString(value: number, stringLength: number) {
  var s = value.toString();
  while (s.length < stringLength) {
    s = '0' + s;
  }
  return s;
}

export function copyToClipboard(str: string) {
  const el = document.createElement('textarea'); // Create a new element
  el.value = str; // Set its value to the string that you want to copy
  document.body.appendChild(el); // Add it to the document
  el.select(); // Select it
  document.execCommand('copy'); // Copy it to clipboard
  document.body.removeChild(el); // Remove it from the document
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
