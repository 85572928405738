import { useOutsideAlerter } from '@/app/hooks/useOutsideAlerter';
import React, { useRef, useState } from 'react';

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClickAway: () => void;
  wide?: boolean;
};

export default function Modal({
  children,
  open,
  onClickAway,
  wide = false
}: Props) {
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef, () => {
    onClickAway();
  });

  return (
    <>
      <input
        readOnly
        type="checkbox"
        checked={open}
        aria-label="modal toggle"
        id={'my-modal-2'}
        className="modal-toggle"
      />
      <div className="modal">
        <div
          ref={modalRef}
          className={`modal-box ${
            wide ? 'w-11/12 max-w-5xl' : ''
          } bg-white text-black`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
