import { useContext } from 'react';
import { DeckContext } from './DeckProvider';

export const useDeck = () => {
  const context = useContext(DeckContext);
  if (context === undefined) {
    throw new Error(`useDeck must be used within a DeckContextProvider.`);
  }
  return context;
};
