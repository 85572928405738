import React, { useEffect, useState } from 'react';
import GoogleButton from '../common/google-button/GoogleButton';
import SubmitButton from '../common/submit-button/SubmitButton';
import MethodDivider from '../common/method-divider/MethodDivider';
import EmailField from '../common/email-field/EmailField';
import PasswordField from '../common/password-field/PasswordField';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import Image from 'next/image';
import orangeLogo from 'public/orange-logo.png';

interface IProps {
  onCreateAccount: () => void;
  onClose: () => void;
}

export default function SignInForm(props: IProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [hasError, setHasError] = useState(false);

  const { trackEvent, analyticsInstance } = useAnalytics();

  const { signInEmailAndPassword, redirectUrl } = useAuth();

  const onSignInEmailAndPassword = async () => {
    trackEvent(analyticsInstance, 'auth_click_sign_in_email');
    const success = await signInEmailAndPassword(email, password);

    setHasError(!success);
  };

  useEffect(() => {
    setHasError(false);
  }, [email, password]);

  console.log(redirectUrl);

  let title =
    redirectUrl?.pathname?.includes('/app/account') &&
    !redirectUrl?.pathname?.includes('/app/account/key')
      ? 'Log in to your account to setup your Limbiks Pro Subscription'
      : 'Log in';

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Image
          className="mx-auto h-10 w-auto"
          src={orangeLogo}
          alt="Limbiks logo"
        />
        <h2 className="mt-6 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {title}
        </h2>

        <p className="mt-2 text-left text-sm text-gray-500">
          Not a member?{' '}
          <a
            href="#"
            onClick={props.onCreateAccount}
            className="font-semibold leading-6 text-primary hover:text-gray-900"
          >
            Create Account
          </a>
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSignInEmailAndPassword();
          }}
        >
          <div className="space-y-2">
            <EmailField
              value={email}
              onChange={(newValue) => setEmail(newValue)}
            />
            <PasswordField
              value={password}
              onChange={(newValue) => setPassword(newValue)}
              label="Password"
              onForgotPassword={props.onClose}
            />
          </div>
          <SubmitButton disabled={!email || !password} text="Sign In" />
          {hasError && (
            <div className="mt-2 p-2 text-red">
              The user does not exist or the password is invalid.
            </div>
          )}
        </form>
        <MethodDivider text="Or continue with" />
        <div className="flex flex-col gap-3 md:flex-row">
          <GoogleButton />
        </div>
      </div>
    </div>
  );
}
