import Link from 'next/link';
import React from 'react';

interface IProps {
  onForgotPassword: () => void;
}

export default function ForgotPassword(props: IProps) {
  return (
    <div className="text-sm">
      <Link href="/forgot" legacyBehavior>
        <span
          onClick={props.onForgotPassword}
          className="font-semibold text-secondary hover:text-gray-900"
        >
          Forgot password?
        </span>
      </Link>
    </div>
  );
}
