import React from 'react';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
}

export default function EmailField(props: IProps) {
  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Email address
      </label>
      <div className="mt-2">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          required
          className="input input-bordered input-sm w-full border-gray-300 bg-white text-black"
        />
      </div>
    </div>
  );
}
