import axios from 'axios';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const postClientError = async (error: string) => {
  await axios.post(`/api/clientError`, error, {
    timeout: 0
  });
};

export default {
  postClientError
};
