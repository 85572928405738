import { Timestamp } from 'firebase/firestore';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { parseISO, addSeconds, addMinutes } from 'date-fns';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

export function getTimeAgo(date: Date) {
  // Assume datetimes as passed in as UTC
  var utc = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return timeAgo.format(utc);
}

export function getUtcNowTimestamp() {
  const now = new Date();
  const utcMilllisecondsSinceEpoch =
    now.getTime() + now.getTimezoneOffset() * 60 * 1000;
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
  return new Timestamp(utcSecondsSinceEpoch, 0);
}

// Check if a signed url is expired or will be expiring in a provided number of minutes
export function isSignedUrlExpiring(
  signedUrl: string,
  expiringInMinutes: number = 0
) {
  // get the query params: https://stackoverflow.com/a/901144/9362404
  const params = new Proxy(new URLSearchParams(signedUrl), {
    get: (searchParams, prop) => searchParams.get(prop as string)
  });
  const creationDate = parseISO(params['X-Amz-Date']);
  const expiresInSecs = Number(params['X-Amz-Expires']);

  const expiryDate = addSeconds(creationDate, expiresInSecs);
  const expiryThreshold = addMinutes(new Date(), expiringInMinutes);

  return expiryDate < expiryThreshold;
}
